import React from "react";
import tANDcData from "../../data/t&c.json";

const PrivacyParagraph = () => {


  const renderTextWithBoldLinks = (text) => {
    if (typeof text !== "string") {
      return ""; // Return an empty string if text is not a string
    }
  
    // Regular expression to identify email addresses
    const emailRegex = /([A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,})/g;
  
    // Regular expression to identify web links (URLs)
    const urlRegex =
      /(?:^|[\s\n])((?:https?|ftp):\/\/[\w/\-?=%.]+\.[\w/\-?=%.]+)/g;
  
    // Replace email addresses and URLs with <a> tags
    const replacedText = text
    .replace(emailRegex, '<a href="mailto:$1" style="text-decoration: none;"><b>$1</b></a>')
    .replace(urlRegex, ' <a href="$1" style="text-decoration: none;"><b>$1</b></a>');
    return replacedText;
  };

  return (
    <div className="container">
      {tANDcData.map((item, index) => (
        <div className="paragraphComp" key={index}>
          {item.companyPreferenceField &&
            item.companyPreferenceField.contentType && (
              <div key={index}>
                <h2
                  className="termsQuestion"
                  style={{
                    margin: 0,
                    color: "#000",
                    fontFamily: "Red Hat Display",
                    fontSize: "10.5px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    marginBottom: "1px",
                  }}
                >
                  {item.companyPreferenceField.contentType === "HEADING" ? (
                    renderTextWithBoldLinks(
                      item.companyPreferenceField.questions[0]
                    )
                  ) : (
                    <span>{item.companyPreferenceField.questions[0]}</span>
                  )}
                </h2>
                {item.companyPreferenceField.answerViewType === "BULLET" ? (
                  <ul>
                    {item.companyPreferenceField.answers.map(
                      (answer, idx) =>
                        answer.trim() !== "" && (
                          <li
                            key={idx}
                            style={{
                              margin: 0,
                              color: "#000",
                              fontFamily: "Red Hat Display",
                              fontSize: "10.5px",
                              fontStyle: "normal",
                              fontWeight: 400,
                              marginBottom: "10px",
                              marginLeft: "10px",
                              textAlign:
                                item.companyPreferenceField.alignmentType ===
                                "RIGHT"
                                  ? "right"
                                  : "left",
                            }}
                            dangerouslySetInnerHTML={{
                              __html: renderTextWithBoldLinks(answer),
                            }}
                          />
                        )
                    )}
                  </ul>
                ) : item.companyPreferenceField.lineSpace ===
                  "NO_SPACING" ? (
                  item.companyPreferenceField.answers.map(
                    (answer, idx) =>
                      answer.trim() !== "" && (
                        <p
                          key={idx}
                          style={{
                            margin: 0,
                            color: "#000",
                            fontFamily: "Red Hat Display",
                            fontSize: "10.5px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            marginBottom: "1px",
                            textAlign:
                              item.companyPreferenceField.alignmentType ===
                              "RIGHT"
                                ? "right"
                                : "left",
                          }}
                          dangerouslySetInnerHTML={{
                            __html: renderTextWithBoldLinks(answer),
                          }}
                        />
                      )
                  )
                ) : item.companyPreferenceField.lineSpace ===
                "SPACING" ? (
                item.companyPreferenceField.answers.map(
                  (answer, idx) =>
                    answer.trim() !== "" && (
                      <p
                        key={idx}
                        style={{
                          margin: 0,
                          color: "#000",
                          fontFamily: "Red Hat Display",
                          fontSize: "10.5px",
                          fontStyle: "normal",
                          fontWeight: 400,
                          marginTop: "10px",
                          textAlign:
                            item.companyPreferenceField.alignmentType ===
                            "RIGHT"
                              ? "right"
                              : "left",
                        }}
                        dangerouslySetInnerHTML={{
                          __html: renderTextWithBoldLinks(answer),
                        }}
                      />
                    )
                )
              ) : (
                  item.companyPreferenceField.answers.map(
                    (answer, idx) =>
                      answer.trim() !== "" && (
                        <p
                          key={idx}
                          style={{
                            margin: 0,
                            color: "#000",
                            fontFamily: "Red Hat Display",
                            fontSize: "10.5px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            marginBottom: "10px",
                            textAlign:
                              item.companyPreferenceField.alignmentType ===
                              "RIGHT"
                                ? "right"
                                : "left",
                          }}
                          dangerouslySetInnerHTML={{
                            __html: renderTextWithBoldLinks(answer),
                          }}
                        />
                      )
                  )
                )}
              </div>
            )}
        </div>
      ))}
    </div>
  );
};

export default PrivacyParagraph;
