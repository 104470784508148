import { apiConfig } from "../../../Api/Config.js";
import { apiEndPoint, apiType, apiStatus } from "../../../Api/ApiConstant.js";
import { ApiService } from "../../../Api/ApiService.js";


let ContactFormController = {
    async postUserDetails(formData) {

        var reqPayload = {
            appStatus: formData.hasApp,
            comments: formData.message,
            emailAddress: formData.email,
            regionName: formData.region,
            type: "CONTACT_US",
            firstName: formData.name,
        };
        return await ApiService.callPOST(
            apiConfig.API_VERSION_1,
            apiEndPoint.EMAIL,
            1,
            reqPayload
        );
    },
}
export default ContactFormController;
