import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes    } from "react-router-dom";
import DrawerAppBar from "./pages/components/header/header";
import HomeMaster from './pages/home/HomeMaster';
import SoftwareMaster from './pages/software/software'
import SolutionsMaster from "./pages/solutions/index";
import Pp from "./pages/privacyPolicy/pp";
import Footer from "./pages/components/footer/footer";
import ScrollToTopButton from "./pages/components/scrollTop/ScrollTop";
import Aboutus from "./pages/aboutus/aboutus";
import FAQ from "./pages/Faq/faq";
import Terms from "./pages/terms/terms";  
import Cookies from "./pages/cookies/cookies";
import Solutions1 from "./pages/solutions/pages/solutions1";
import Solutions2 from "./pages/solutions/pages/solutions2";
import Solutions3 from "./pages/solutions/pages/solutions3";
import Solutions4 from "./pages/solutions/pages/solutions4";
import ContactMain from "./pages/contact/index";
import Solutions5 from "./pages/solutions/pages/solutions5";
import Solutions6 from "./pages/solutions/pages/solutions6";
import Faq from "./pages/Faq/faq";
import Tnc from "./pages/privacyPolicy/pp";
function App() {

  const routes = [
    { label: "Home", path: "/", component: HomeMaster },
    { label: "Software", path: "/software", component: SoftwareMaster },
    { label: "Solutions", path: "/solutions", component: SolutionsMaster },
    { label: "Contact", path: "/contact", component: ContactMain },
  ];

  const mobileRoutes = [
    { label: "Home", path: "/", component: HomeMaster,  },
    { label: "Software", path: "/software", component: SoftwareMaster },
    { label: "Solutions", path: "/solutions", component: SolutionsMaster },
    { label: "Contact", path: "/contact", component: ContactMain },
    { label: "FAQ", path: "/faq", component: Faq },
    { label: "Terms & Conditions", path: "/terms_and_conditions", component: Terms },
    { label: "Privacy Policy", path: "/privacy_policy", component: Tnc },
    { label: "Cookies Policy", path: "/cookies_policy", component: Cookies },
  ]
  
  return (
    <Router>
      <DrawerAppBar routes={routes} mobileRoutes={mobileRoutes} />
      <Routes>
        {routes.map((route, index) => (
          <Route key={index} path={route.path} element={<route.component />} />
        ))}
        {mobileRoutes.map((route, index) => (
          <Route key={index} path={route.path} element={<route.component />} />
        ))}
        
        <Route path="/privacy_policy" element={<Pp /> } />
        <Route path="/faq" element={<FAQ/> } />
        <Route path="/terms_and_conditions" element={<Terms/> } />
        <Route path="/cookies_policy" element={<Cookies/> } />
        <Route path="/value_for_fleet_management" element={<Solutions1 />} />
        <Route path="/value_for_motor_insurers" element={<Solutions2 />} />
        <Route path="/mobile_telematics_providers_are_commonly_seen_as_disruptive_innovators" element={<Solutions3 />} />
        <Route path="/insurance_companies_are_setting_for_a_profit_gain_from_the_mobile_telematics_systems" element={<Solutions4 />} />
        <Route path="/what_does_it_mean_to_drive_well_outside_of_any_emergency_situation?" element={<Solutions5 />} />
        <Route path="/connected_car" element={<Solutions6 />} />
        <Route path="/about_us" element={<Aboutus />} />
      </Routes>
      <Footer />
      <ScrollToTopButton/>
    </Router>
  );
}

export default App;
