import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Button, CardActionArea, CardActions, Container, Paper, Box } from "@mui/material";
import { Grid } from "@mui/material";
import img1 from '../../assets/images/ss-1.png'
import img2 from '../../assets/images/ss-2.png'
import img3 from '../../assets/images/ss-3.png'
// import img4 from '../../assets/images/image6.jpg'
import img5 from '../../assets/images/7d3620049c2e76c6d07fa3ea47d323c9.jpg'
import arrow from '../../assets/images/upper arrow.svg'
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import carImageHomePage from "../../assets/images/carHomePageImage.jpg"

const HomeSection5 = () => {

    const hoverStyles = {
        '&:hover': {
            backgroundColor: 'blue', // Change to whatever color you desire
            // Add any other styles for hover effect
        },
    };

    useEffect(() => {
        // Scroll to the top when the component mounts
        window.scrollTo(0, 0);

        // Optionally, you can also scroll to the top when a link is clicked
        const scrollToTop = () => {
            window.scrollTo(0, 0);
        };

        // Attach the scroll to top function to all links with class "scroll-to-top"
        const links = document.querySelectorAll(".scroll-to-top");
        links.forEach(link => {
            link.addEventListener("click", scrollToTop);
        });

        // Clean up event listeners when the component unmounts
        return () => {
            links.forEach(link => {
                link.removeEventListener("click", scrollToTop);
            });
        };
    }, []);



    const contentData = [
        {
            src: img1,
            text: "Value for Fleet Management",
            // buttonText: "ALL",
            // symbol: arrow,
            linkTo: "/value_for_fleet_management", // Add the link you want to redirect to
        },
        {
            src: img2,
            text: "Value for Motor Insurers",
            // buttonText: "CASE STUDY",
            // symbol: arrow,
            linkTo: "/value_for_motor_insurers", // Add the link you want to redirect to
        },
        {
            src: img3,
            text: "Mobile telematics provider",
            // buttonText: "WHITE PAPER",
            // symbol: arrow,
            linkTo: "/mobile_telematics_providers_are_commonly_seen_as_disruptive_innovators", // Add the link you want to redirect to
        },

    ];


    return (
        <>
            <div id='section5' >
                <div class='container-fluid main'>
                    <Grid container className="sub1" marginLeft="10px">
                        <Grid item xs={6} sm={6} className='content2' display={"flex"} justifyContent={"left"} alignItems={"center"}><p style={{whiteSpace:"nowrap"}}>Success Stories</p></Grid>
                        <Grid item xs={6} sm={6} className='content2' display={"flex"} justifyContent={"right"} alignItems={"center"}>
                            <Link to="/solutions" style={{ textDecoration: "none" }} className="scroll-to-top" >
                            <Button variant="contained"   className="viewmore" sx={{position:"relative",bottom:"5px",whiteSpace:"nowrap"}}>
                                View More
                            </Button>
                        </Link></Grid>
                    </Grid>
                    <div class='sub1' style={{ display: "flex", justifyContent: "center", padding: "10px" }}>
                        <Grid
                            container
                            spacing={2}
                            style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "50px" }}
                        >
                            {contentData.map((content, index) => (
                                <Grid item xs={12} sm={4} md={4} lg={4} key={index}>
                                    <Link to={content.linkTo} style={{ textDecoration: "none" }} className="scroll-to-top">
                                        <Card
                                            sx={{
                                                borderRadius: "10px",
                                                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                                                
                                            }}
                                        >
                                            <CardActionArea >
                                                <img
                                                    src={content.src}
                                                    alt={`Img ${index + 1}`}
                                                    style={{
                                                        height: "291px",
                                                        width: "100%",
                                                        objectFit: "cover",
                                                    }}
                                                    class='img-fluid'
                                                />

                                                {/* <CardContent>
                                                    <CardActions
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "space-between",
                                                            padding: "0px",
                                                        }}
                                                    >
                                                        <Button
                                                            size="small"
                                                            sx={{
                                                                backgroundColor: "#FFCF24",
                                                                color: "#000000",
                                                                fontFamily: "segoe ui",
                                                                fontWeight: "600",
                                                                fontSize: "12px",

                                                                alignContent: "center",
                                                            }}
                                                        >
                                                            {content.buttonText}
                                                        </Button>
                                                        <p
                                                            style={{
                                                                fontFamily: "Segoe UI",
                                                                fontSize: "14px",
                                                                fontWeight: "400",
                                                                color: "#545252",
                                                                lineHeight: "28px",
                                                                alignContent: "center",
                                                            }}
                                                        >
                                                            {content.date}
                                                        </p>
                                                    </CardActions>
                                                    <Typography variant="body2" sx={{ color: "#000000", fontFamily: 'Segoe UI', fontSize: { md: "18px", lg: "15px" }, fontWeight: '600', marginTop: '10px' }}>
                                                        {content.text}
                                                    </Typography>
                                                </CardContent> */}

                                            </CardActionArea>
                                        </Card>
                                        <Typography className="cardtypo" sx={{fontSize: { md: "18px", lg: "17px" }, fontWeight: '600', marginTop: '10px' }}>
                                            {content.text}
                                        </Typography>
                                    </Link>
                                </Grid>
                            ))}
                        </Grid>
                    </div>


                    <div class='sub2  ' style={{ padding: "10px" }}>
                        <Box sx={{display:{xs:"none",sm:"block"}}} className='image-container'>
                            <img src={img5} width='100%' className='img-fluid' style={{ borderRadius: "20px", height: '450px', objectFit: "cover" }} />
                            <h1 className='image-heading'>“What does it mean to drive well, outside of any emergency situation?”</h1>
                        </Box>
                        <Box sx={{display:{xs:"block",sm:"none"}}} className='image-container'>
                            <img src={carImageHomePage} width='100%' className='img-fluid' style={{ borderRadius: "20px", height: '450px', objectFit: "cover" }} />
                            <h1 className='image-heading'>“What does it mean to drive well, outside of any emergency situation?”</h1>
                        </Box>
                    </div>
                </div>
            </div>
        </>
    );
}


export default HomeSection5