import { Typography } from '@mui/material';
import React from 'react';

const TitleComp = ({ data }) => {
  return (
    <div id='footer'>
      <Typography sx={{ fontFamily:"Red Hat Display", fontWeight:600, fontSize:"16px"}}>
        {data.title}
      </Typography>
      <svg xmlns="http://www.w3.org/2000/svg" width="165" height="2" viewBox="0 0 165 2" fill="none">
        <path d="M0 1H164.5" stroke="#FFCF24" />
      </svg>  
      <ul style={{ padding: "0" }}>
        {data.list.map((item, index) => {
          if (index === 0 && item.startsWith('+')) {
            // Phone number, render as a tel: link
            return (
              <li className='foot-link' style={{ listStyle: 'none', textAlign: "left", padding: "0.3rem", fontFamily:"Red Hat Display", fontSize:"14px", fontWeight:400 }} key={index}>
                <a href={`tel:${item}`} >{item}</a>
              </li>
            );
          } else if (item.includes('@')) {
            // Email address, render as a mailto: link
            return (
              <li className='foot-link' style={{ listStyle: 'none', textAlign: "left", padding: "0.3rem", fontFamily:"Red Hat Display", fontSize:"14px", fontWeight:400 }} key={index}>
                <a href={`mailto:${item}`} >{item}</a>
              </li>
            );
          } else {
            // Neither phone number nor email, render as plain text
            return (
              <li style={{ listStyle: 'none', textAlign: "left", padding: "0.3rem" }} key={index}>
                {item}
              </li>
            );
          }
        })}
      </ul>
    </div>
  );
};

export default TitleComp;
