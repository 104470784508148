import React from "react";
import { Typography, Box } from "@mui/material";
import { useLocation } from "react-router-dom";

const SolutionsTitles = () => {
  // Get the current location including the pathname
  const location = useLocation();

  // Define title based on the pathname
  let title = "Default Title"; // Default title if no matching pathname is found

  switch (location.pathname) {
    case "/value_for_fleet_management":
      title = "Value for Fleet Management";
      break;
      case "/value_for_motor_insurers":
      title = "Value for Motor Insurers";
      break;
      case "/mobile_telematics_providers_are_commonly_seen_as_disruptive_innovators":
      title = "Mobile telematics providers are commonly seen as disruptive innovators";
      break;
      case "/insurance_companies_are_setting_for_a_profit_gain_from_the_mobile_telematics_systems":
      title = "Insurance Companies are setting for a Profit Gain from the Mobile Telematics Systems";
      break;
      case "/what_does_it_mean_to_drive_well_outside_of_any_emergency_situation":
      title = "“What does it mean to drive well, outside of any emergency situation?”";
      break;
      case "/connected_car":
      title = "Connected Car";
      break;
      
    // Add more cases for other pathnames if needed
    default:
      break;
  }

  return (
    <Box sx={{ padding:location.pathname.includes("value_for_motor_insurers") || location.pathname.includes("connected_car")    ?  "40px 40px 15px 10px" : "40px 40px 0px 40px" }} className="container-fluid">
      <Typography sx={{fontFamily: 'Red Hat Display',fontSize:{ md:"26px", xs:"20px", sm:"20px"} ,fontWeight: 700, textAlign:"left",color:"#000000", lineHeight:"24px"}}>{title}</Typography>
    </Box>
  );
};

export default SolutionsTitles;
