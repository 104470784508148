import React, { useEffect, useState } from "react";
import { Box, Container, FormHelperText, Grid, Paper, Typography } from "@mui/material";
import RiskAlertNextImg_1 from "../../../../assets/images/riskstyleEstimation2.png"
import RiskAlertNextImg_2 from "../../../../assets/images/abnoramlstatedetection2.png"
import RiskAlertNextImg_3 from "../../../../assets/images/drivingstyle2.png"
import RiskAlertNextImg_4 from "../../../../assets/images/strainevaluation2.png"
import anticapativeIcon from "../../../../assets/images/anticapativeIcon.svg"
import expertIcon from "../../../../assets/images/expertIcon.svg"
import riskyIcon from "../../../../assets/images/riskyIcon.svg"
import unknownIcon from "../../../../assets/images/unknownIcon.svg"
import abnormalExpertIcon from "../../../../assets/images/abnormalExpertIcon.svg"
import abnormalRiskyIcon from "../../../../assets/images/abnormalRiskyIcon.svg"
import normalIcon from "../../../../assets/images/normalIcon.svg"
import agressiveIcon from "../../../../assets/images/agressiveIcon.svg"
import ChaoticIcon from "../../../../assets/images/ChaoticIcon.svg"
import sportiveIcon from "../../../../assets/images/sportiveIcon.svg"
import ecoIcon from "../../../../assets/images/ecoIcon.svg"
import sbrakinglowIcon from "../../../../assets/images/sbrakinglowIcon.svg"
import sbrakingmediumIcon from "../../../../assets/images/sbrakingmediumIcon.svg"
import sbrakinghighIcon from "../../../../assets/images/sbrakinghighIcon.svg"
import sAccelerationlowIcon from "../../../../assets/images/sAccelerationlowIcon.svg"
import sAccelerationmediumIcon from "../../../../assets/images/sAccelerationmediumIcon.svg"
import sAccelerationhighIcon from "../../../../assets/images/sAccelerationhighIcon.svg"
import scorneringlowIcon from "../../../../assets/images/scorneringlowIcon.svg"
import scorneringmediumIcon from "../../../../assets/images/scorneringmediumIcon.svg"
import scorneringhighIcon from "../../../../assets/images/scorneringhighIcon.svg"
import './riskalert.css'
import SoftwareSlider from "../SoftwareSlider";
const data = [
  {
    imageSrc_1: RiskAlertNextImg_1,
    imageSrc_2: RiskAlertNextImg_2,
    imageSrc_3: RiskAlertNextImg_3,
    imageSrc_4: RiskAlertNextImg_4,
  },

];

const RiskAlertNext = () => {

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);


  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  return (
    <div  style={{ padding:"0rem 0 3rem 0"}} id="riskalert">
   <div style={{padding:"30px"}}>
    <Grid container   spacing={2} >
      {data.map((item, index) => (
        <Grid container spacing={2} key={index}>
          {/* First Item: Image 1 */}

        { screenWidth > 899 ? (
          <SoftwareSlider/>
        ):(
          <>
          <Grid container sx={{ display:'flex', alignContent:'center', justifyContent:"center",}}>
            <Box  sx={{paddingLeft:"30px"}}>
                <Box className="riskalertParentBox">
                  <Box>
                    <img src={RiskAlertNextImg_1} alt="RiskAlertNextImg_1" width={"100%"}/>
                  </Box>
                  <Box className="riskalertContentBox">
                    <Box>
                      <Typography sx={{ fontFamily: "Red Hat Display", fontSize:"16px", fontWeight:700}}>Risk Style Estimation </Typography>
                      <Box display={'flex'} alignItems={'center'} gap={10} mt={2}>
                        <Box display={"flex"} alignItems={"center"} gap={1}>
                          <img src={anticapativeIcon} alt="icon"/>
                          <Typography sx={{ fontFamily: "Red Hat Display", fontSize:"15px", fontWeight:600}}>Anticipative</Typography>
                        </Box>
                        <Box display={"flex"} alignItems={"center"} gap={1}>
                          <img src={expertIcon} alt="icon"/>
                          <Typography sx={{ fontFamily: "Red Hat Display", fontSize:"15px", fontWeight:600}}>Expert</Typography>
                        </Box>
                      </Box>
                      <Box display={'flex'} alignItems={'center'} gap={15.5} mt={2}>
                        <Box display={"flex"} alignItems={"center"} gap={1}>
                          <img src={riskyIcon} alt="icon"/>
                          <Typography sx={{ fontFamily: "Red Hat Display", fontSize:"15px", fontWeight:600}}>Risky</Typography>
                        </Box>
                        <Box display={"flex"} alignItems={"center"} gap={1}>
                          <img src={unknownIcon} alt="icon"/>
                          <Typography sx={{ fontFamily: "Red Hat Display", fontSize:"15px", fontWeight:600}}>Unknown</Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box className="riskalertParentBox">
                  <Box>
                    <img src={RiskAlertNextImg_2} alt="RiskAlertNextImg_1" width={"100%"}/>
                  </Box>
                  <Box className="riskalertContentBox">
                    <Box>
                      <Typography sx={{ fontFamily: "Red Hat Display", fontSize:"16px", fontWeight:700}}>Abnormal State Detection </Typography>
                      <Box display={'flex'} alignItems={'center'} gap={13.5} mt={2}>
                        <Box display={"flex"} alignItems={"center"} gap={1}>
                          <img src={sportiveIcon} alt="icon"/>
                          <Typography sx={{ fontFamily: "Red Hat Display", fontSize:"15px", fontWeight:600}}>Normal</Typography>
                        </Box>
                        <Box display={"flex"} alignItems={"center"} gap={1}>
                          <img src={abnormalExpertIcon} alt="icon"/>
                          <Typography sx={{ fontFamily: "Red Hat Display", fontSize:"15px", fontWeight:600}}>Expert</Typography>
                        </Box>
                      </Box>
                      <Box display={'flex'} alignItems={'center'} gap={15.5} mt={2}>
                        <Box display={"flex"} alignItems={"center"} gap={1}>
                          <img src={abnormalRiskyIcon} alt="icon"/>
                          <Typography sx={{ fontFamily: "Red Hat Display", fontSize:"15px", fontWeight:600}}>Risky</Typography>
                        </Box>
                        <Box display={"flex"} alignItems={"center"} gap={1}>
                          <img src={unknownIcon} alt="icon"/>
                          <Typography sx={{ fontFamily: "Red Hat Display", fontSize:"15px", fontWeight:600}}>Unknown</Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box className="riskalertParentBox">
                  <Box>
                    <img src={RiskAlertNextImg_3} alt="RiskAlertNextImg_1" width={"100%"}/>
                  </Box>
                  <Box className="riskalertContentBox">
                    <Box>
                      <Typography sx={{ fontFamily: "Red Hat Display", fontSize:"16px", fontWeight:700}}>Driving Style</Typography>
                      <Box display={'flex'} alignItems={'center'} gap={12.9} mt={2}>
                        <Box display={"flex"} alignItems={"center"} gap={1}>
                          <img src={sportiveIcon} alt="icon"/>
                          <Typography sx={{ fontFamily: "Red Hat Display", fontSize:"15px", fontWeight:600}}>Sportive</Typography>
                        </Box>
                        <Box display={"flex"} alignItems={"center"} gap={1}>
                          <img src={agressiveIcon} alt="icon"/>
                          <Typography sx={{ fontFamily: "Red Hat Display", fontSize:"15px", fontWeight:600}}>Agressive</Typography>
                        </Box>
                      </Box>
                      <Box display={'flex'} alignItems={'center'} gap={13.6} mt={2}>
                        <Box display={"flex"} alignItems={"center"} gap={1}>
                          <img src={ChaoticIcon} alt="icon"/>
                          <Typography sx={{ fontFamily: "Red Hat Display", fontSize:"15px", fontWeight:600}}>Chaotic</Typography>
                        </Box>
                        <Box display={"flex"} alignItems={"center"} gap={1}>
                          <img src={normalIcon} alt="icon"/>
                          <Typography sx={{ fontFamily: "Red Hat Display", fontSize:"15px", fontWeight:600}}>Normal</Typography>
                        </Box>
                      </Box>
                      <Box display={'flex'} alignItems={'center'} gap={16.8} mt={2}>
                        <Box display={"flex"} alignItems={"center"} gap={1}>
                          <img src={ecoIcon} alt="icon"/>
                          <Typography sx={{ fontFamily: "Red Hat Display", fontSize:"15px", fontWeight:600}}>Eco</Typography>
                        </Box>
                        <Box display={"flex"} alignItems={"center"} gap={1}>
                          <img src={unknownIcon} alt="icon"/>
                          <Typography sx={{ fontFamily: "Red Hat Display", fontSize:"15px", fontWeight:600}}>Unknown</Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box className="riskalertParentBox">
                  <Box>
                    <img src={RiskAlertNextImg_4} alt="RiskAlertNextImg_1" width={"100%"}/>
                  </Box>
                  <Box className="riskalertContentBox">
                    <Box>
                      <Typography sx={{ fontFamily: "Red Hat Display", fontSize:"16px", fontWeight:700}}>Stress / Strain Evaluation </Typography>
                      <Box display={'flex'} alignItems={'center'} gap={10} mt={2}>
                        <Box display={"flex"} alignItems={"center"} gap={1}>
                          <img src={sbrakinglowIcon} alt="icon"/>
                          <Box>
                            <Typography sx={{ fontFamily: "Red Hat Display", fontSize:"15px", fontWeight:600}}>S.Braking</Typography>
                            <FormHelperText sx={{marginTop:"-3px"}}>(Low)</FormHelperText>
                          </Box>
                        </Box>
                        <Box display={"flex"} alignItems={"center"} gap={1}>
                          <img src={scorneringlowIcon} alt="icon"/>
                          <Box>
                            <Typography sx={{ fontFamily: "Red Hat Display", fontSize:"15px", fontWeight:600}}>S.Cornering</Typography>
                            <FormHelperText sx={{marginTop:"-3px"}}>(Low)</FormHelperText>
                          </Box>
                        </Box>
                      </Box>
                      <Box display={'flex'} alignItems={'center'} gap={10} mt={2}>
                        <Box display={"flex"} alignItems={"center"} gap={1}>
                          <img src={sbrakingmediumIcon} alt="icon"/>
                          <Box>
                            <Typography sx={{ fontFamily: "Red Hat Display", fontSize:"15px", fontWeight:600}}>S.Braking</Typography>
                            <FormHelperText sx={{marginTop:"-3px"}}>(Medium)</FormHelperText>
                          </Box>
                        </Box>
                        <Box display={"flex"} alignItems={"center"} gap={1}>
                          <img src={scorneringmediumIcon} alt="icon"/>
                          <Box>
                            <Typography sx={{ fontFamily: "Red Hat Display", fontSize:"15px", fontWeight:600}}>S.Cornering</Typography>
                            <FormHelperText sx={{marginTop:"-3px"}}>(Medium)</FormHelperText>
                          </Box>
                        </Box>
                      </Box>
                      <Box display={'flex'} alignItems={'center'} gap={10} mt={2}>
                        <Box display={"flex"} alignItems={"center"} gap={1}>
                          <img src={sbrakinghighIcon} alt="icon"/>
                          <Box>
                            <Typography sx={{ fontFamily: "Red Hat Display", fontSize:"15px", fontWeight:600}}>S.Braking</Typography>
                            <FormHelperText sx={{marginTop:"-3px"}}>(High)</FormHelperText>
                          </Box>
                        </Box>
                        <Box display={"flex"} alignItems={"center"} gap={1}>
                          <img src={scorneringhighIcon} alt="icon"/>
                          <Box>
                            <Typography sx={{ fontFamily: "Red Hat Display", fontSize:"15px", fontWeight:600}}>S.Cornering</Typography>
                            <FormHelperText sx={{marginTop:"-3px"}}>(High)</FormHelperText>
                          </Box>
                        </Box>
                      </Box>
                      <Box display={'flex'} alignItems={'center'} gap={{sm:0, xs:6}} mt={2}>
                        <Box display={"flex"} alignItems={"center"} gap={1}>
                          <img src={sAccelerationlowIcon} alt="icon"/>
                          <Box>
                            <Typography sx={{ fontFamily: "Red Hat Display", fontSize:"15px", fontWeight:600}}>S.Acceleration</Typography>
                            <FormHelperText sx={{marginTop:"-3px"}}>(Low)</FormHelperText>
                          </Box>
                        </Box>
                        <Box display={"flex"} alignItems={"center"} gap={1}>
                          <img src={sAccelerationmediumIcon} alt="icon"/>
                          <Box>
                            <Typography sx={{ fontFamily: "Red Hat Display", fontSize:"15px", fontWeight:600}}>S.Acceleration</Typography>
                            <FormHelperText sx={{marginTop:"-3px"}}>(Medium)</FormHelperText>
                          </Box>
                        </Box>
                      </Box>
                      <Box display={'flex'} alignItems={'center'} gap={15.5} mt={2}>
                        <Box display={"flex"} alignItems={"center"} gap={1}>
                          <img src={sAccelerationhighIcon} alt="icon"/>
                          <Box>
                            <Typography sx={{ fontFamily: "Red Hat Display", fontSize:"15px", fontWeight:600}}>S.Acceleration</Typography>
                            <FormHelperText sx={{marginTop:"-3px"}}>(High)</FormHelperText>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
            </Box>
          </Grid>
          </>
        )}
        </Grid>
      ))}
    </Grid>
    </div>
    </div>
  );
};

export default RiskAlertNext;
