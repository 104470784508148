import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import Logo from "../../../assets/images/Footerlogo.svg";
import "./header.css";
import { Link, useLocation } from "react-router-dom";
import btnimg from "../../../assets/images/btnimage.png";
import closeIcon from "../../../assets/images/closeIcon.svg";
import { Menu, MenuItem, SwipeableDrawer, Typography } from "@mui/material";
import hamBurgerIcon from "../../../assets/images/hamburgerMenu.svg";

const drawerWidth = 240;

export default function DrawerAppBar({ routes, mobileRoutes }) {

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ backgroundColor:"#615AE4",width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250, height: "88vh", textAlign:"center" }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Box sx={{ textAlign:"right", marginRight:"10px", marginTop:"5px"}} onClick={toggleDrawer(anchor, false)}><img src={closeIcon} alt="closeIcon" width={25}/></Box>
      <List>
        {mobileRoutes.map((text, index) => (
          <>
          <Link to={text.path} style={{ textDecoration:"none"}}>
          <ListItem key={text} disablePadding className="mobileMenuText">
            <ListItemButton sx={{ textAlign:"center"}} >
              {/* <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon> */}

              {/* <Box key={text}/> */}
              <ListItemText primary={text.label}>{text.label}</ListItemText>
              
            </ListItemButton>
            
          </ListItem>
          <Box key={index} sx={{textAlign:"center",}} className="mobileMenuLine"/>
          </Link>
          </>
        ))}
      </List>

    </Box>
  );

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const location = useLocation(); // Get the current location
  const currentPath = location.pathname;



  const scrollToTop = () => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  };

  return (
    <Box sx={{ display: "flex" }} className="header">
      <CssBaseline />
      <AppBar >
        <Toolbar
          sx={{
            backgroundColor: "white",
            padding: { xs: '0px',sm:"0 1rem", md: "0 1rem", lg: "0 1rem" }
          }}
        >
          <Grid container className="sm-navbar" sx={{ marginLeft:{xs:"0px",sm:"20px"} }}>
            <Grid item xs={4} sm={4} md={3} lg={2} display={"flex"} sx={{ justifyContent: { xs: "center", sm: "flex-start" }, alignItems: "center" }} className="header-image">
              <Link to="/" onClick={scrollToTop}>
                <img src={Logo} alt="Logo" />
              </Link>
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={8} display={{ xs: "none", md: "flex" }} justifyContent="center" alignItems="center">
              <Grid
                container
                spacing={{ xs: 0, md: 2, lg: 3, xl: 4 }}
                sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
              >
                {routes.map((item) => (
                  <Grid item key={item.label}>
                    <Button
                      className="list-item-button"
                      component={Link}
                      to={item.path}
                      onClick={scrollToTop}
                      style={{
                        textTransform: "none",
                        fontWeight: currentPath === item.path ? "900" : "normal",
                        color: currentPath === item.path ? "#615AE4" : "black"
                      }}
                    >
                      {item.label}
                    </Button>

                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item xs={2} sm={1} display={{ xs: "flex", md: "none" }} >
            <div>
                {['bottom'].map((anchor) => (
                  <React.Fragment key={anchor}>
                    <Button onClick={toggleDrawer(anchor, true)}><img src={hamBurgerIcon} alt="hamBurgerIcon"/></Button>
                    <SwipeableDrawer
                      anchor={anchor}
                      open={state[anchor]}
                      onClose={toggleDrawer(anchor, false)}
                      onOpen={toggleDrawer(anchor, true)}
                    >
                      {list(anchor)}
                    </SwipeableDrawer>
                  </React.Fragment>
                ))}
              </div>
              </Grid>

            <Grid item xs={2} sm={3} md={3} lg={2} sx={{ justifyContent: { xs: "center", sm: "flex-end" }, display:{xs:"none",sm:"flex"}, alignItems: "center" }} >
              <Link to="https://app.motivai.tech/" target="_blank" onClick={scrollToTop}>
                <Button variant="contained" className="login" style={{ textTransform: "none" }}>
                  Web App Login
                </Button>
              </Link>
            </Grid>

            <Grid xs={12}  sx={{display:{xs:"block",sm:"none"},height:"45px"}} >
              <Link to="https://app.motivai.tech/" target="_blank" onClick={scrollToTop}>
                <Button variant="contained" className="mobileResLoginButton" sx={{ textTransform: "none",backgroundColor:"#615AE4",height:"45px" }}>
                  Web App Login
                </Button>
              </Link>
            </Grid>


          </Grid>
         
         

        </Toolbar>
        
      </AppBar>

    </Box>
  );
}
