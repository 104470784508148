import React, { useEffect, useState } from "react";
import SolutionsTitles from "../components/title";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import img1 from '../../../assets/images/fleet1.png'
import img2 from '../../../assets/images/fleet2.png'

import bgImg from '../../../assets/images/phoneBg-Image.png'
import bgImg1 from '../../../assets/images/iPhoneVFMmobile.png'

import icon1 from '../../../assets/images/rncIcon.svg'
import icon2 from '../../../assets/images/ddcIcon.svg'
import icon3 from '../../../assets/images/dccIcon.svg'

import card1 from '../../../assets/images/card-vfmi.png'
import card2 from '../../../assets/images/card-mtp.png'
import card3 from '../../../assets/images/card-iamt.png'

import './solutionsPages.css'
import { Link } from "react-router-dom";
import Sstories from "../components/successStories";
import Contact from "../../contact/contact";

const Solutions1 = () => {


    const [screenWidth, setScreenWidth] = useState(window.innerWidth);


    useEffect(() => {
      const handleResize = () => {
        setScreenWidth(window.innerWidth);
      };
  
      window.addEventListener("resize", handleResize);
  
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);

    const iconContent = [
        {
            icon: icon1,
            title: "Road Network Context",
            subtitle: "Road type, road familiarity, rural / urban, regions, speed zones, etc."
        },
        {
            icon: icon2,
            title: "Driving Dynamics Context",
            subtitle: "Speed, acceleration, braking, cornering, risk against road attributes, reckless events."
        },
        {
            icon: icon3,
            title: "Driving Condition Context",
            subtitle: "Day / night, rush hours, weekday/weekend, trip duration."
        }
    ];

    const contentData = [
        {
            src: card1,
            text: "Value for Motor Insurers",
            // buttonText: "ALL",
            // symbol: arrow,
            linkTo: "/value_for_motor_insurers", 
            
        },
        {
            src: card2,
            text: "Mobile Telematics Provider",
            // buttonText: "CASE STUDY",
            // symbol: arrow,
            linkTo: "/mobile_telematics_providers_are_commonly_seen_as_disruptive_innovators", // Add the link you want to redirect to
        },
        {
            src: card3,
            text: "Insurance and Mobile Telematics",
            // buttonText: "WHITE PAPER",
            // symbol: arrow,
            linkTo: "/insurance_companies_are_setting_for_a_profit_gain_from_the_mobile_telematics_systems", // Add the link you want to redirect to
        },
        
    ];


    return (
        <Grid id="fleet" marginTop={{xs:"100px",sm: "40px", md:"60px"}} >
            <SolutionsTitles />
            <Grid container padding={"30px 25px 0px 35px"}>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <Box
                    component="img"
                    src={img1}
                    alt="InsuranceTopImg"
                    sx={{ width: { xs: "100%", sm: "98%" }, display:{xs:"none", sm:"block"} }}
                ></Box>
                </Grid>
                <Grid
                item
                sm={6}
                md={6}
                lg={6}
                xl={6}
                sx={{ display: { xs: "block", sm: "flex" }, justifyContent: "right", }}
                >
                <Box
                    component="img"
                    src={img2}
                    alt="InsuranceTopRightImg"
                    sx={{ width: { xs: "100%", sm: "98%" } }}
                ></Box>
                </Grid>
            </Grid>
            <Grid className="container-fluid" item xs={12} mt={3} mb={2} sx={{ padding: "10px 40px" }}>
                <Grid container spacing={3}>
                    {iconContent.map((index, key) => (
                        <Grid item xs={12} sm={4} key={key} sx={{width: {md:"343px", sm:"0px", xs:"0px"}}}>
                            <img src={index.icon} height={"42px"} />
                            <Typography className="icontitle"><p>{index.title}</p></Typography>
                            <Box ><Typography className="iconsubtitle"><p>{index.subtitle}</p></Typography></Box>                            
                        </Grid>
                    ))}
                </Grid>
            </Grid>
            <Grid className="subBg" mt={0}>
                <div className='container-fluid sub4'>
                    <Grid container>
                        <Grid item xs={12} sm={6} >
                            <Grid container>
                                <Grid item xs={12} sm={6} display={{ md:"block", sm: "block", xs: "flex" }} justifyContent={{ md:"flexstart", sm: "space-between", xs: "space-between" }}>
                                    <Box className="leftContent" >A driving solution for fleet management</Box>
                                    <a href="#contact" >
                                        <Button variant="contained" className="more" style={{ whiteSpace:"nowrap"}}>
                                            Enquire Now
                                        </Button>
                                    </a>
                                </Grid>
                                <Grid item sm={6} />
                            </Grid>

                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Grid container>
                                <Grid item xs={12} sm={12} className='box'>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div className='head'>Realtime alerts</div>
                                        <div className='content'>
                                            <li>
                                                Alerts driver in real time with anticipation
                                            </li>
                                            <li>
                                                Cultivating safe driving behaviour culture
                                            </li>
                                        </div>
                                    </div>
                                </Grid>
                                <div className="line3"></div>
                                <Grid item xs={12} sm={12} className='box'>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div className='head'>Social Responsibility</div>
                                        <div className='content'>
                                            <li>
                                                Improves images with customer less late delivery due to accident
                                            </li>
                                            <li>
                                                Copes with corporate social responsibility
                                            </li>
                                            <li>
                                                Reduces accident rate
                                            </li>
                                        </div>
                                    </div>
                                </Grid>
                                <div className="line3"></div>
                                <Grid item xs={12} sm={12} className='box'>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div className='head'>Reduce claims cost </div>
                                        <div className='content'>
                                            <li>
                                                Access key details like driving risk alerts, anticipation, self confidence, vehicle impact location etc.
                                            </li>
                                            <li>
                                                Helps for a better negotiation with motor insurer
                                            </li>
                                        </div>
                                    </div>
                                </Grid>
                                <div className="line3"></div>
                                <Grid item xs={12} sm={12} className='box' sx={{paddingBottom:{xs:"15px",sm:"15px"}}}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div className='head'>Improve your book of business</div>
                                        <div className='content'>
                                            <li>
                                                Improve your book by identifying, engaging, and rewarding safe drivers
                                            </li>
                                            <li>
                                                Reduce workers compensation costs and number of days away from work
                                            </li>
                                            <li>
                                                Reduces repair and replacement cost
                                            </li>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Box className='bgImage' sx={{ display :{ md:"block", sm:"none", xs:"none"}}}>
                            <img src={bgImg} />
                        </Box>
                    </Grid>
                </div>
            </Grid>
            <Grid container sx={{ display: {md:"none", sm:'block', xs:"block", textAlign:"center", justifyContent:"center"}}}>
                <Box sx={{padding:"30px 30px 0px 30px"}}>
                    <img src={bgImg1} alt='bgImg1' style={{ width:"250px", height:"500px"}}/>
                </Box>
            </Grid>
            <Box id="contact" sx={{marginLeft:{md:"40px", sm:"0px", xs:"10px"}}}><Contact/></Box>
            <Box sx={{ padding:"0px 20px"}}><Sstories contentData={contentData} /></Box>
        </Grid>
    )
}


export default Solutions1