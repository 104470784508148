import React from "react";
import "./Home.css";
import video from "../../assets/videos/2012085_View_Aerial_City_3840x2160.mp4";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

const HomeSection1 = () => {
  const handleButtonClick = () => {
    // Replace 'your_link_url_here' with the actual URL you want to open.
    const linkUrl = "https://app.motivai.tech/";

    // Open the link in a new window or tab.
    window.open(linkUrl, "_blank");
  }; 
  return (
    <>
      <div id="section1" style={{marginTop:"64px"}}>
        <div className="background-video-container">
          <video className="background-video" autoPlay loop muted playsInline>
            <source src={video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>

          <div class="container-fluid content-overlay">
            <div class="row main" style={{ display: "flex" }}>
              <div
                class="col-sm-6 sub1"
                style={{
                  alignSelf: "flex-start",
                  paddingTop: "40px",
                  paddingLeft: "30px",
                }}
              >
                <h1>Drive Safe, Arrive Safe.</h1>
              </div>
              <div class="col-sm-1" />

              <div class="col-sm-5 sub2 " style={{ alignSelf: "flex-end" }}>
                <div
                  class="row"
                  style={{
                    display: "flex",
                    // flexDirection: "column",
                    width: "96%",
                  }}
                >
                  <div class="col-sm-12">
                    <h6>
                     Onboard Hybrid Artificial Intelligence Platform for ADAS, Autonomous Driving, Telematics, Road Safety.
                    </h6>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                    }}
                  >
                    <div class="col-sm-12 line"></div>
                  </div>

                  <div class="col-sm-12">
                    <h5>
                      We believe that it is time to reshape the automotive
                      telematics for everyone, everywhere with Cognitive AI
                      software, devices and services.
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div>
          <div
            class="homebtn"
            style={{ position: "absolute", top: "200px", left: "45px" }}
          >
            <Box display="flex" justifyContent={"center"} marginTop={"20px"}>
              <Button
                className="custom-button3"
                sx={{
                  color: "black",
                  background: "white",
                  fontWeight: "500",
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "600",
                  padding: "12px 14px",
                  borderRadius: "40px",
                  textTransform: "capitalize",
                  fontSize: "20px",
                  ":hover": {
                    background: "#615AE4",
                    color: "#fff"
                  }
                }}
                onClick={handleButtonClick}
              >
                <img
                  src={btnimg} // Replace with the URL or path to your image
                  alt="Login Icon"
                  style={{
                    marginRight: "8px", // Adjust the margin as needed for spacing
                    width: "45px", // Adjust the width as needed for the image size
                    height: "45px", // Adjust the height as needed for the image size
                  }}
                />
                 Login to Motiv AI web
              </Button>
            </Box>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default HomeSection1;
