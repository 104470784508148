import { Grid } from "@mui/material";
import React from "react";
import phImg from '../../assets/images/HomeLast.png'
import playstore from '../../assets/images/playStoreHomeLast.svg'
import appstore from '../../assets/images/appStoreHomeLast.png'
import { Link } from "react-router-dom";
import './Home.css'


const HomeSection6 = () => {
    return (
        <Grid xs={12} id="section6" className='container-fluid' marginBottom={"30px"} marginTop={"20px"}>
            <Grid container>
                <Grid item xs={12} md={5} display={"flex"} alignItems={"center"} justifyContent={"center"}><img src={phImg} width={"90%"} /></Grid>
                <Grid item xs={12} md={7}>
                    <Grid container>
                        <Grid item md={1.5} />
                        <Grid item sm={8} md={9} xs={12} textAlign={{ sm: "left", xs: "center" }} marginBottom={{ sm: "0px", xs: "20px" }} marginTop={5} className="innerGrid" >
                            <div className='Head'>Download the <span className='sec7subHeadBlue'>Motiv AI App</span></div>
                            <div className='Text' style={{ lineHeight:"26px"}}>Telematics that reduces accident rate by more than 25% and then that drives down operating costs for fleets and reduces number of claims for insurers.</div>
                            <div className='images'>
                                <div className="playStore">
                                    <Link to={"https://play.google.com/store/apps/details?id=com.montbleu.roadsafety"} target="blank">
                                        <img src={playstore} alt="play Store" width={"100%"} />
                                    </Link>
                                </div>
                                <div className="appStore">
                                    <Link to={"https://apps.apple.com/in/app/motiv-ai/id1640693505"} target="blank">
                                        <img src={appstore} alt="app Store" width={"100%"} />
                                    </Link>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid >
    )
}
export default HomeSection6