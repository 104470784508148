import React, { useState, useEffect } from "react";
import { Grid, Box, Container, Typography } from "@mui/material";
import Logo from "../../../assets/images/Footerlogo.png";
import PrivLogo from "../../../assets/images/footer-privacy-logo.png";
import TitleComp from "./footerTitleComp";
import "./footer.css";
import { FaFacebook, FaLinkedin, FaInstagram } from "react-icons/fa";
import { Link } from "react-router-dom";

const dataArray = [
  {
    title: "Quicklinks",
    items: [
      { label: "Home", url: "/" },
      { label: "Software", url: "/software" },
      { label: "Solutions", url: "/solutions" },
      { label: "Contact", url: "/contact" },
    ],
  },
  {
    title: "Locate Us",
    items: [
      {
        label: "122, 3<sup>rd</sup> Floor, Appusamy Road, Red Fields, Race Course,<br>Coimbatore -641 045, TN, India.",
        url: "https://goo.gl/maps/wdee5e2AwM5ArwTy6",
      },
    ],
  },
];

const phoneArray = [
  {
    title: "Get in touch",
    list: ["+91 98946 00136", "selva.ramanujam@motivai.tech"],
  },
];

const Footer = () => {
  const handleScrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 899);

  const handleResize = () => {
    setIsMobileView(window.innerWidth <= 899);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="footer-main footer_bg">
      <Grid container margin="0 auto" className="footerBox">
        <Grid
          item
          xs={12}
          md={5}
          sx={{ marginTop: "10px", padding: { xs: "10px", lg: "50px" } }}
        >
          <Box className="container" sx={{ width: { xs: "100%", md: "100%" } }}>
            <div className="footer-image-div">
              <img
                style={{ objectFit: "cover" }}
                className="footer-image"
                src={Logo}
                alt="Logo"
              />
            </div>
            <Typography
              sx={{
                textAlign: { xs: "justify", sm: "justify", lg: "left" },
                color: "white",
                fontSize: "12px",
                fontFamily: "Red Hat Display",
              }}
              className="footerDetail"
            >
              Motiv AI is an automotive software company brings new paradigm for
              road safety in ADAS and autonomous driving with unique cognitive
              artificial intelligence embedded software that measures 20 times
              per second the level of prudence of driving behavior (in-use
              monitoring) and with other AI modules for fleets, telematics,
              usage based insurance, and new vehicles (OEM)
            </Typography>
            {!isMobileView && (
              <div
                className="socialMediaLink"
                style={{
                  display: "flex",
                  gap: "20px",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  marginTop: "30px ",
                }}
              >
                <a
                  href="https://www.facebook.com/fbmotivai/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="socialLink"
                >
                  <FaFacebook size={15} color="white" />
                </a>
                <a
                  href="https://www.linkedin.com/company/motiv-ai/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="socialLink"
                >
                  <FaLinkedin size={15} color="white" />
                </a>
                <a
                  href="https://www.instagram.com/in.motivai"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="socialLink"
                >
                  <FaInstagram size={15} color="white" />
                </a>
              </div>
            )}
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          md={7}
          sx={{ marginTop: "60px", padding: { sm: "0", lg: "10px" } }}
          className="footerItem"
        >
          <Box className="container" sx={{ width: { xs: "100%" } }}>
            <Grid container className="footer_main">
              <Grid item xs={10} sm={10}>
                <Grid
                  container
                  spacing={{ lg: 2, md: 1, sm: 2, xs: 0 }}
                  className="footer-links"
                >
                  {dataArray.map((data, index) => (
                    <Grid
                      style={{
                        color: "white",
                        fontSize: "12px",
                        fontFamily: "Red Hat Display",
                        marginTop: isMobileView ? "30px" : 0,
                      }}
                      key={index}
                      item
                      xs={12}
                      md={4.5}
                      lg={3.5}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Red Hat Display",
                          fontSize: "16px",
                          fontWeight: 600,
                          color: "#fff",
                        }}
                      >
                        {data.title}
                      </Typography>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="165"
                        height="2"
                        viewBox="0 0 165 2"
                        fill="none"
                      >
                        <path d="M0 1H164.5" stroke="#FFCF24" />
                      </svg>
                      {data.items.map((item, itemIndex) => (
                        <li
                          style={{ listStyle: "none", paddingTop: "1px" }}
                          key={itemIndex}
                        >
                          {data.title === "Locate Us" ? (
                            <a
                              className="footerText"
                              href={item.url}
                              target="_blank"
                              rel="noopener noreferrer"
                              dangerouslySetInnerHTML={{ __html: item.label }}
                            ></a>
                          ) : (
                            <Link
                              className="footerText"
                              to={item.url}
                              onClick={handleScrollTop}
                            >
                              <p style={{ marginBottom: "0px" }}>{item.label}</p>
                            </Link>
                          )}
                        </li>
                      ))}
                    </Grid>
                  ))}
                  {phoneArray.map((data, index) => (
                    <Grid
                      style={{
                        color: "white",
                        fontSize: "12px",
                        fontFamily: "Red Hat Display",
                        marginTop: isMobileView ? "30px" : "0px",
                      }}
                      item
                      key={index}
                      className="getInTouch"
                    >
                      <TitleComp data={data} />
                      {isMobileView && (
                        <div
                          className="socialMediaLink2"
                          style={{
                            display: "flex",
                            gap: "20px",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <a
                            href="https://www.facebook.com/fbmotivai/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <FaFacebook size={15} color="white" />
                          </a>
                          <a
                            href="https://www.linkedin.com/company/motiv-ai/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <FaLinkedin size={15} color="white" />
                          </a>
                          <a
                            href="https://www.instagram.com/in.motivai"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <FaInstagram size={15} color="white" />
                          </a>
                        </div>
                      )}
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid
                item
                xs={2}
                sm={2}
                className="privlogo"
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <div>
                  <img
                    style={{ objectFit: "cover" }}
                    src={PrivLogo}
                    alt="Logo"
                  />
                </div>
                <div>
                  <h6
                    style={{
                      color: "white",
                      textAlign: "center",
                      fontSize: "12px",
                      fontWeight: "700",
                      fontFamily: "Segoe UI",
                      paddingTop: "10px",
                    }}
                  >
                    “We do not own or sell your data"
                  </h6>
                </div>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Box
                sx={{
                  width: { md: "75%", sm: "100%", xs: "100%" },
                  marginTop: isMobileView ? "10px" : 0,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="100%"
                  height="2"
                  viewBox="0 0 1000 2"
                  fill="none"
                >
                  <path d="M0 1H1000" stroke="#FFCF24" />
                </svg>
              </Box>

              <div>
                <div
                  className="socialMedia"
                  style={{
                    display: "flex",
                    color: "white",
                  }}
                >
                  <div className="socialMediaLinks">
                    <Link
                      to="/terms_and_conditions"
                      onClick={handleScrollTop}
                      className="footerLastText"
                    >
                      Terms & Conditions
                    </Link>

                    <Link
                      to="/privacy_policy"
                      onClick={handleScrollTop}
                      className="footerLastText"
                    >
                      Privacy Policy
                    </Link>
                    <Link
                      to="/cookies_policy"
                      onClick={handleScrollTop}
                      className="footerLastText"
                    >
                      Cookies Policy
                    </Link>

                    <Link
                      to="/faq"
                      onClick={handleScrollTop}
                      className="footerLastText"
                    >
                      FAQ
                    </Link>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12}></Grid>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default Footer;
