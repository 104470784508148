import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import { useState } from "react";
import {
  Avatar,
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import "./Home.css";
import img1 from "../../assets/images/tabImage1.png";
import img2 from "../../assets/images/TabImage2.png";
import img3 from "../../assets/images/TabImage3.png";
import drivingImage from "../../assets/images/drivingmenImgNew.svg";
import drivingInsightsIcon from "../../assets/images/DrivingInsightsIcons.svg";
import { Button } from "@mui/material";
import mobilityInsightsImage from "../../assets/images/MobilityInsightsIcons.svg";
import mobilityInsightsIcon from "../../assets/images/MobilityInsights.svg";
import userEngagementIcon from "../../assets/images/UserEngagementImageNew.svg";
import UserEngagementIconNew from "../../assets/images/UserEngagementIconsNew.svg";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export default function SliderHome() {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Container fluid>
      <Grid
        xs={12}
        sx={{
          bgcolor: "background.paper",
          display: { xs: "none", sm: "none", md: "block" },
        }}
        id="sliderSection"
      >
        <Tabs
          value={value}
          onChange={handleChange}
          centered
          variant="fullWidth"
          TabIndicatorProps={{
            style: { background: "#615AE4", height: "5px" },
          }}
        >
          <Tab
            label="Driving Insights"
            className="tabName"
            {...a11yProps(0)}
          ></Tab>
          <Tab
            label="Mobility Insights"
            className="tabName"
            {...a11yProps(1)}
          ></Tab>
          <Tab
            label="User Engagement"
            className="tabName"
            {...a11yProps(2)}
          ></Tab>
        </Tabs>
        <TabPanel value={value} index={0}>
          <img src={img1} width={"100%"} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <img src={img2} width={"100%"} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <img src={img3} width={"100%"} />
        </TabPanel>
      </Grid>

      <Grid
        xs={12}
        
        sx={{
          bgcolor: "background.paper",
          display: { xs: "block", sm: "block", md: "none" },
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Card sx={{ maxWidth: 345, borderRadius: "20px 20px 0px 0px",marginTop:"25px",}}>
            <CardMedia
              sx={{ height: 190 }}
              image={drivingImage}
              title="green iguana"
            />
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Avatar
                  sx={{
                    width: "30px",
                    height: "30px",
                    backgroundColor: "#41C083",
                  }}
                >
                  <img src={drivingInsightsIcon} alt="drivingInsightsIcon" />
                </Avatar>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      textTransform: "capitalize",
                      paddingLeft: "7px",
                      color: "#1B1128",
                      fontSize: "14px",
                      fontWeight: 700,
                    }}
                  >
                    Driving Insights
                  </Typography>
                  <Typography
                    className="cardTimeView"
                    sx={{
                      display: "flex",
                      alignItems: "end",
                      textTransform: "capitalize",
                      color: "#7F7E7F",
                      fontSize: "12px",
                      fontWeight: 600,
                    }}
                  >
                    10m ago
                  </Typography>
                </Box>
              </Typography>
              <Typography
                variant="body2"
                sx={{ fontSize: "14px", color: "#1B1128", fontWeight: 600 }}
              >
                Fantastic, you are highly active to anticipative alerts.
              </Typography>
            </CardContent>
          </Card>
        </Box>
      </Grid>
      <Grid
        xs={12}
        
        sx={{
          bgcolor: "background.paper",
          display: { xs: "block", sm: "block", md: "none" },marginTop:"25px"
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Card sx={{ maxWidth: 345, borderRadius: "20px 20px 0px 0px" }}>
            <CardMedia
              sx={{ height: 190 }}
              image={mobilityInsightsImage}
              title="green iguana"
            />
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Avatar
                  sx={{
                    width: "30px",
                    height: "30px",
                    backgroundColor: "#F88216",
                  }}
                >
                  <img src={mobilityInsightsIcon} alt="mobilityInsightsIcon" />
                </Avatar>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      textTransform: "capitalize",
                      paddingLeft: "7px",
                      color: "#1B1128",
                      fontSize: "14px",
                      fontWeight: 700,
                    }}
                  >
                    Mobility Insights
                  </Typography>
                  <Typography
                    className="cardTimeView"
                    sx={{
                      display: "flex",
                      alignItems: "end",
                      textTransform: "capitalize",
                      color: "#7F7E7F",
                      fontSize: "12px",
                      fontWeight: 600,
                    }}
                  >
                    05m ago
                  </Typography>
                </Box>
              </Typography>
              <Typography
                variant="body2"
                sx={{ fontSize: "14px", color: "#1B1128", fontWeight: 600 }}
              >
                Great Going ! Your score has improved from the last trip.
              </Typography>
            </CardContent>
          </Card>
        </Box>
      </Grid>

      <Grid
        xs={12}
        
        sx={{
          bgcolor: "background.paper",
          display: { xs: "block", sm: "block", md: "none" },marginTop:"25px"
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Card sx={{ maxWidth: 345, borderRadius: "20px 20px 0px 0px",marginBottom:"25px"}}>
            <CardMedia
              sx={{ height: 190 }}
              image={userEngagementIcon}
              title="green iguana"
            />
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Avatar
                  sx={{
                    width: "30px",
                    height: "30px",
                    backgroundColor: "#615AE4",
                  }}
                >
                  <img src={UserEngagementIconNew} alt="drivingInsightsIcon" />
                </Avatar>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      textTransform: "capitalize",
                      paddingLeft: "7px",
                      color: "#1B1128",
                      fontSize: "14px",
                      fontWeight: 700,
                    }}
                  >
                    User Engagement
                  </Typography>
                  <Typography
                    className="cardTimeView"
                    sx={{
                      display: "flex",
                      alignItems: "end",
                      textTransform: "capitalize",
                      color: "#7F7E7F",
                      fontSize: "12px",
                      fontWeight: 600,
                    }}
                  >
                    03m ago
                  </Typography>
                </Box>
              </Typography>
              <Typography
                variant="body2"
                sx={{ fontSize: "14px", color: "#1B1128", fontWeight: 600 }}
              >
                Hurray! You have moved up to Rank No. 1 on the leaderboard.

              </Typography>
            </CardContent>
          </Card>
        </Box>
      </Grid>
    </Container>
  );
}
