import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Box, Container, Grid, Typography, useTheme } from '@mui/material';
import "../../home/Home.css"
import img1 from "../../../assets/images/riskstyleestimation.png"
import img2 from "../../../assets/images/Abnormal State Detection.png"
import img3 from '../../../assets/images/Driving Style.png'
import img4 from '../../../assets/images/Strain Evaluation.png'

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

export default function SoftwareSlider() {
    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Container fluid>
            <Grid xs={12} sx={{ bgcolor: 'background.paper' }} id="sliderSection">
                <Tabs value={value} onChange={handleChange} centered variant="fullWidth"
                          TabIndicatorProps={{
                            style: { background: "#615AE4", height: "5px" },
                          }}
                >
                    <Tab label="Risk Style Estimation " className='tabName softwareslider'  {...a11yProps(0)}></Tab>
                    <Tab label="Abnormal State Detection" className='tabName softwareslider' {...a11yProps(1)}></Tab>
                    <Tab label="Driving Style" className='tabName softwareslider' {...a11yProps(2)}></Tab>
                    <Tab label="Understand Driving Insights" className='tabName softwareslider' {...a11yProps(3)}></Tab>
                </Tabs>
                <TabPanel value={value} index={0}>
                    <img src={img1} width={"100%"} />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <img src={img2} width={"100%"} />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <img src={img3} width={"100%"} />
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <img src={img4} width={"100%"} />
                </TabPanel>
            </Grid>
        </Container>

    );
}
