import React from "react";
import SolutionsTitles from "../components/title";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import img1 from '../../../assets/images/mt1.png'
import img2 from '../../../assets/images/mt2.png'

import bgImg from '../../../assets/images/sol2-bg.png'

import icon1 from '../../../assets/images/RNC-icon.png'
import icon2 from '../../../assets/images/DDC-icon.png'
import icon3 from '../../../assets/images/DCC-icon.png'

import card1 from '../../../assets/images/card-iamt.png'
import card2 from '../../../assets/images/card-driveWell.png'
import card3 from '../../../assets/images/card-cc.png'

import './solutionsPages.css'
import { Link } from "react-router-dom";
import Sstories from "../components/successStories";
import Contact from "../../contact/contact";

const Solutions3 = () => {

    const contentData = [

        {
            src: card1,
            text: "Insurance and Mobile Telematics",
            // buttonText: "WHITE PAPER",
            // symbol: arrow,
            linkTo: "/insurance_companies_are_setting_for_a_profit_gain_from_the_mobile_telematics_systems", // Add the link you want to redirect to
        },        
        {
            src: card2,
            text: "What Does It Mean To Drive Well",
            // buttonText: "CASE STUDY",
            // symbol: arrow,
            linkTo: "/what_does_it_mean_to_drive_well_outside_of_any_emergency_situation", // Add the link you want to redirect to
        },
        {
            src: card3,
            text: "Connected Car",
            // buttonText: "WHITE PAPER",
            // symbol: arrow,
            linkTo: "/connected_car", // Add the link you want to redirect to
        },

    ];


    return (
        <Grid id="fleet" marginTop={{ xs: "100px", sm: "40px", md: "60px" }}>
            <SolutionsTitles />
            <Grid className="container-fluid solution3-paragraph" item xs={12} p={"10px 40px 20px 40px"}>
                <div>Say an automotive insurance provider adopts a mobile telematics insurance option while also maintaining the traditional insurance model for many of its other customers. After a year or two only a small but significant percentage of the company’s customers have signed on for the mobile usage-based program — perhaps because the company hasn’t had an opportunity to properly market its UBI offering. Is the usage-based insurance effort a failure?</div>
                <div style={{ marginTop: "10px" }}>No, and for one major reason: it shows clients, even those who don’t opt for the mobile UBI model (at this time) that their insurance provider is an innovator and actively developing new ideas and insurance programs that can benefit their clients. Even if a customer determines that mobile telematics isn’t right for them at this time, they may change their mind down the road. And they’ll be happy about their insurance provider and its forward-thinking approach to expanding their product offerings.</div>
                <div style={{ marginTop: "10px" }}>Then, of course, there are the rewards for customers who do sign up for a mobile telematics program. So long as they drive safe, they’ll see their premiums go down, giving them a feeling that their insurance provider really cares about rewarding them for safe on-road behavior. The relationship that emerges between the insurance provider and the customer will be closer and more intimate, making a safe driver less likely to sever their ties with their provider in favor of another automotive insurance company.</div>
            </Grid>
            <Grid className="container-fluid" item xs={12} mt={2} sx={{ padding: "0px 40px" }}>
            <Grid container>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <Box
                    component="img"
                    src={img1}
                    alt="mtimage"
                    sx={{ width: { xs: "100%", sm: "98%" } }}
                ></Box>
                </Grid>
                <Grid
                item
                sm={6}
                md={6}
                lg={6}
                xl={6}
                sx={{ display: { xs: "none", sm: "flex" }, justifyContent: "right", }}
                >
                <Box
                    component="img"
                    src={img2}
                    alt="mtimage"
                    sx={{ width: { xs: "10%", sm: "98%" } }}
                ></Box>
                </Grid>
            </Grid>
            </Grid>
            <Grid xs={12} sx={{ padding: "40px 40px 0px 40px" }} className="container-fluid">
                <Typography sx={{ fontFamily: 'Red Hat Display', fontSize: "20px", fontWeight: 700, textAlign: "left", lineHeight:"24px" }}>Mobile telematics systems help reduce costs associated with claims </Typography>
            </Grid>
            <Grid className="container-fluid solution3-paragraph" item xs={12} p={"10px 40px 0px 40px"}>
                <div style={{ marginTop: "10px" }}>If you created an automotive insurance program that clearly rewarded drivers for safe driving, what kind of people do you expect would sign up for such a program? The answer: far less risky (and therefore far more profitable) customers. According to one consulting firm that’s researched this issue extensively, on average it can lead to a 57 percent decrease in total claims costs. </div>
                <div style={{ marginTop: "10px" }}>Meanwhile, more risky drivers — or drivers who are more likely to get into a traffic accident or receive a speeding ticket — will be convinced to avoid the usage-based insurance model and may even leave the insurance company, thereby flocking to other insurance companies that offer more traditional (and less profitable) insurance programs.</div>
            </Grid>
            <Box id="contact" sx={{marginLeft:{md:"40px", sm:"0px", xs:"10px"}}}><Contact /></Box>
            <Box sx={{ padding:"10px 20px"}}><Sstories contentData={contentData} /></Box>
        </Grid>
    )
}


export default Solutions3