import React from 'react'
import b2b from '../../assets/images/B2B.jpg'
import b2c from '../../assets/images/B2C.jpg'
import whitelabel from '../../assets/images/whitelabel.png'
import b2bcar from '../../assets/images/b2b-car.png'
import b2candroid from '../../assets/images/b2c-android.png'
import b2capple from '../../assets/images/b2c-apple.png'
import b2btrain from '../../assets/images/b2b-train.png'
import b2bwheel from '../../assets/images/b2b-wheel.png'
import b2bbuilding from '../../assets/images/b2b-building.png'
import b2bship from '../../assets/images/b2b-ship.png'
import b2bdelivery from '../../assets/images/b2b-delivery.png'
import Grid from '@mui/material/Grid';
const HomeSection4 = () => {
    return (
        <>
            <div id='section4' >
                <div class='container-fluid main' style={{ paddingBottom: '60px' }}>




                    <Grid container className='sub1'>
                        <Grid xs={12} className='c1'><p style={{ textTransform: "none" }} >Business Model</p></Grid>
                        <Grid xs={12} className='c3' marginTop={0}><p>User licenses of software component sold PER DEVICE / PER YEAR</p></Grid>
                    </Grid>

                    <div class='sub2' style={{ marginTop: "30px" }}>
                        <div class='container'>
                            <Grid container spacing={2} >


                                <Grid item lg={4} md={6} xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} className='c1'>
                                            <h5>B2B</h5>
                                        </Grid>
                                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                            <img src={b2b} alt='img' className='img-fluid' style={{ width: '200px', height: '200px', borderRadius: '50%', objectFit: "cover", objectPosition: "right" }} />
                                        </Grid>
                                        <Grid item xs={12} className='c2' style={{ display: "flex", height: "70px", alignItems: "center" }}>
                                            <h5>Mobile App Telematics</h5>
                                        </Grid>
                                        <Grid item xs={12} style={{ display: "flex", alignItems: "center", justifyContent: "center", paddingTop: "0" }}>
                                            <div className="line"></div>

                                        </Grid>
                                        <Grid item xs={12} sm={7} sx={{marginLeft:{xs:"15px",sm:"0px"}}}>
                                            <Grid container spacing={2} className='c4' style={{ display: "flex", alignItems: "baseline" }}  >
                                                <Grid item xs={2}><img src={b2bcar} height='20px' alt='b2b-car' /></Grid>
                                                <Grid item xs={10}><h6>Motor Insurance Companies</h6></Grid>
                                                <Grid item xs={2}><img src={b2btrain} height='20px' alt='b2b-car' /></Grid>
                                                <Grid item xs={10}><h6>Govt Transport Corporations</h6></Grid>
                                                <Grid item xs={2}><img src={b2bwheel} height='20px' alt='b2b-car' /></Grid>
                                                <Grid item xs={10}><h6>Driving Institutes</h6></Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} sm={5} sx={{marginLeft:{xs:"15px",sm:"0px"}}}>
                                            <Grid container spacing={2} className='c4' style={{ display: "flex", alignItems: "baseline" }}>
                                                <Grid item xs={2}><img src={b2bbuilding} height='20px' alt='b2b-car' /></Grid>
                                                <Grid item xs={10}><h6>Corporate Industries</h6></Grid>
                                                <Grid item xs={2}><img src={b2bship} height='20px' alt='b2b-car' /></Grid>
                                                <Grid item xs={10}><h6>Fleet Companies</h6></Grid>
                                                <Grid item xs={2}><img src={b2bdelivery} height='20px' alt='b2b-car' /></Grid>
                                                <Grid item xs={10}><h6>Delivery Platforms</h6></Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>




                                <Grid item lg={4} md={6} xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} className='c1'>
                                            <h5>B2C</h5>
                                        </Grid>
                                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                            <img src={b2c} alt='img' className='img-fluid' style={{ width: '200px', height: '200px', borderRadius: '50%', objectFit: "cover" }} />
                                        </Grid>
                                        <Grid item xs={12} className='c2' style={{ display: "flex", height: "70px", alignItems: "center" }}>
                                            <h5>Smart Phone App
                                            </h5>
                                        </Grid>
                                        <Grid item xs={12} style={{ display: "flex", alignItems: "center", justifyContent: "center", paddingTop: "0" }}>
                                            <div className="line"></div>

                                        </Grid>

                                        <Grid item xs={12} style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "20px" }}>
                                            <div style={{ padding: "10px" }}>
                                                <img src={b2candroid} />
                                            </div>
                                            <div style={{ padding: "10px" }}>
                                                <img src={b2capple} />
                                            </div>


                                        </Grid>


                                    </Grid>
                                </Grid>



                                <Grid item lg={4} md={6} xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} className='c1'>
                                            <h5>WHITE LABELLING</h5>
                                        </Grid>
                                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                            <img src={whitelabel} alt='img' className='img-fluid' style={{ width: '200px', height: '200px', borderRadius: '50%' }} />
                                        </Grid>
                                        <Grid item xs={12} className='c2' style={{ display: "flex", flexDirection: "column", height: "70px", alignItems: "center" }}>
                                            <h5 >ACC – Predictive Cruise Control</h5>
                                        </Grid>

                                        <Grid item xs={12} style={{ display: "flex", alignItems: "center", justifyContent: "center", paddingTop: '0' }}>
                                            <div className="line"></div>

                                        </Grid>
                                        <Grid item xs={12}>
                                            <ul className='section4List'>
                                                <li >OEM/Tier 1</li>
                                                <li >ACC - Predictive Cruise Control</li>
                                                <li >ISA - Intelligent Speed Assist</li>
                                                <li >Connected 2Wheelers</li>
                                            </ul>
                                        </Grid>
                                    </Grid>
                                </Grid>



                            </Grid>
                        </div>
                    </div>





                </div>
            </div >

        </>
    )
}

export default HomeSection4