import React from "react";
import "./index.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useState } from "react";
const SwiperComponent = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const slickSettings = {
    dots: true,
    infinite: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: (dots) => (
      <div style={{ textAlign: "center" }}>
        <ul style={{ display: "inline-block" }}>
          {Array.from({ length: 6 }, (_, index) => (
            <li key={index}>{dots[index]}</li>
          ))}
        </ul>
      </div>
    ),
    customPaging: (i) => (
      <div
        style={{
          width: "10px",
          height: "10px",
          borderRadius: "50%",
          background: i === currentSlide % 6 ? "#615AE4" : "black",
          marginTop: "30px",
          transition: "background 0.3s ease",
        }}
      ></div>
    ),
    beforeChange: (currentSlide, nextSlide) => {
      setCurrentSlide(nextSlide);
    },
  };

  const swiperData = [
    {
      title: "We Innovate",
      subtitle: "“I Drive Well, the Problem is the Other Driver”: A Study About the Self-Assessment of the Ability to Drive ",
      content:
        "The fact that human beings make erroneous estimates of their own ability to drive motor vehicles may have important safety implications. Thus, assessment of own driving skills is as important dimension of road risk management and can be of fundamental importance in both, reducing the likelihood of an accident and increasing safety in driving. ",
    },
    {
      title: "We Innovate",
      subtitle:
        "“What does it mean to drive well, outside of any emergency situation?”",
      content:
        "The idea of reformulating this question from perspective of prudence and have chosen to estimate in real time level of caution (or prudence) of a driving behavior regarding context. The notion of context is essential and is used to define prudence as ‘the adequacy of the behavior to the context, without necessarily knowing the whole context or all the possible consequences of a behavior.  ",
    },
   
    {
      title: "We Innovate",
      subtitle:
        "“Unfortunately, what some drivers do, they commit incautious acts but do not have an accident  or even a bad surprise, and they take on bad habits in terms of road safety”",
      content:
        "Prudence consists in acting in anticipation of potential surprises, without knowing everything about context. From this definition, we define the notion of risk as being lack of prudence. If the driver commits to an imprudence, and this does not lead to consequences most of time, but rarely this recklessness leads to near-miss situation or emergency, for which accident is avoided most often, Finally, some accident occurs, of which a small part is serious and an even smaller part is fatal. ",
    },
    {
      title: "We Innovate",
      subtitle:
        "“Detection of entry into risk triangle, and action for a rapid exit: undo unsafe act”",
      content:
        "Continuous monitoring of the value of risk so as never to enter, when possible, into risk triangle, to do this, we define a maximum authorized risk lower than the high risk which corresponds to entering triangle. The risk is referred to as ‘risk target’. If we manage not to exceed risk target, then we don’t enter into risk triangle, and we do not commit an unsafe act. This is the second level of anticipation.  ",
    },
    {
      title: "We Innovate",
      subtitle: "“Functional safety: on-board computing of risk of accident”",
      content:
        "Since lack of caution (risk) is calculated 20 times per second while driving, this information can be used to help driver avoid emergency situations as much as possible: for this, as soon as value of risk exceeds the threshold for entry into risk triangle (detection of an 'unsafe act'): a visual, audible or haptic interface informs driver who then becomes aware that it would be great to return to a safer driving behavior.  ",
    },
    // Add more data objects as needed
  ];
  return (
    <div className="container" id="swiper">
      <Slider {...slickSettings}>
        {swiperData.map((slide, index) => (
          <div key={index} className="col-sm-12 main">
            <div
              style={{ display: "flex", flexDirection: "column", }}
              className="col-sm-12"
            >
              {/* <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  
                }}
              >
                <div className="line"></div>
              </div> */}
              <div className="col-sm-12 content1" >
                <p>{slide.title}</p>
              </div>

              <div className="col-sm-12 content2">
                <div class="row">
                  <div class="col-sm-2"></div>
                  <div class="col-sm-8">
                    <p>{slide.subtitle}</p>
                  </div>
                  <div class="col-sm-2"></div>
                </div>
              </div>

              <div className="col-sm-12">
                <div class="row">
                  <div class="col-sm-2"></div>
                  <div class="col-sm-8">
                    <p style={{textAlign:"center"}}>{slide.content}</p>
                  </div>
                  <div class="col-sm-2"></div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default SwiperComponent;
