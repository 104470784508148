import React, { useState, useEffect } from "react";
import SafeDrivingScoreImg from "../../../../assets/images/safedrivinscoremobile.png";
import SafeDrivingScoreImg_2 from "../../../../assets/images/safedrivescore_2.png";
import { Box, Container, Grid, Typography } from "@mui/material";
import centerimage_large from "../../../../assets/images/fast-forward 1.png";
import centerimage_small from "../../../../assets/images/fast-forward 2.png";
import './safedriving.css'

const data = [
  {
    imageSrc: SafeDrivingScoreImg,
    imageSrc_2: SafeDrivingScoreImg_2,
    title: "Safe Driving Score",
  },
];

const SafeDrivingScore = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div style={{ padding: "0rem 0 3rem 0" }} id='safedriving'>
      <div style={{padding:"30px"}}>
        {data.map((item, index) => (
          <Grid container spacing={2} key={index}>
            {/* Left: Image */}
            <Grid
              item
              xs={12}
              md={5.6}
              sx={{
                display: { sm: "flex" },
                justifyContent: { sm: "center" },
              }}
            >
              <Box elevation={3} style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                <Box style={{ padding: "1rem",marginLeft:{md:"13%", xs:"5%", sm:"5%"} }}>
                  <Typography
                    variant="h5"
                    textAlign={"left"}
                    sx={{ color: "#000", fontSize: "20px", fontWeight: "600", fontFamily: "Red Hat Display" }}
                  >
                    {item.title}
                  </Typography>
                  <Typography  mb={2} textAlign={"left"} sx={{ color: "#000", fontSize: "18px", fontFamily: "Red Hat Display" }}>
                  <span style={{fontWeight:"600"}}>  Pay As You Drive | How You Drive </span>
                  </Typography>
                </Box>
                <div style={{ flex: 1 }}>
                  <img
                    src={item.imageSrc}
                    alt="Image"
                    style={{
                      maxWidth: "100%",
                      height: "auto",
                    }}
                  />
                </div>
              </Box>
            </Grid>
            <Grid item xs={12} md={0.4} sx={{ display: { xs:"flex",sm: "flex", md: "flex", lg: "flex" }, justifyContent: { xs:"center",sm: "center", md: "center", lg: "center" }, alignItems: { xs:"center",sm: "center", md: 'center', lg: "center" } }} >
              <img
                src={screenWidth < 899 ? centerimage_small : centerimage_large}
                alt="Image"
                style={{ height: "30px" }}
              />
            </Grid>

            <Grid item xs={12} md={6} display={"flex"} alignItems={"center"} sx={{ display: { sm: "flex" }, justifyContent: { sm: "center" } }}>
              <Box elevation={3}>
                <div style={{ flex: 1 }}>
                  <Typography className="drivingscore-output" variant="body1" mb={1} textAlign={"center"} sx={{ color: "#000", fontSize: "20px", fontWeight: "600", fontFamily: "Red Hat Display", marginTop: {xs:"0px",sm:"0px",md:"-25px",lg:"-25px"}}}>Output</Typography>
                  <img
                    src={item.imageSrc_2}
                    alt="Image"
                    style={{
                      maxWidth: "100%",
                      height: "auto",
                    }}
                  />
                </div>
              </Box>
            </Grid>
          </Grid>
        ))}
      </div>
    </div>
  );
};

export default SafeDrivingScore;
