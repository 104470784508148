import { Box, Container, Stack, Typography, Grid } from "@mui/material";
import React from "react";
import "./howItWork.css";
import workImg from "../../../../assets/images/howitwork_bg.jpg";
import ruduLogo from "../../../../assets/images/howitwork_center.png";
import playImg from "../../../../assets/images/howitwork_play.png";
import Alert from "../../../../assets/images/alertIcon1.svg"
import Insights from "../../../../assets/images/insightsIcon2.svg"
import Highscore from "../../../../assets/images/scoreIcon3.svg"

const HowItWork = () => {
  return (
    <Container className="howItWork">
      <Box mt={0} sx={{marginRight:{md:0, sm:"8px", xs:"8px"}, paddingLeft:{ md:0, sm:"10px", xs:"10px"}}}>
        <Typography
          variant="h5"
          sx={{fontFamily:"Red Hat Display", fontSize:"20px", fontWeight:600, color:"#000", textAlign:{md:"center", xs:"left", sm:'left', }}}
        >
          HOW IT WORKS
        </Typography>
      </Box>
      <Box mt={1}  sx={{marginRight:{md:0, sm:"10px", xs:"10px"}, paddingLeft:{ md:0, sm:"10px", xs:"10px"}}}>
        <Typography
          variant="h4"
          sx={{fontFamily:"Red Hat Display", fontSize:"18px", fontWeight:700, color:"#000", textAlign:{md:"center", xs:"left", sm:'left'}}}
        >
          “I Drive Well, the Problem is the Other Driver”
        </Typography>
      </Box>
      <Box className="workImg">
        <Grid container sx={{ display:{md:"flex", sm:"", xs:""}, justifyContent:"space-between", alignItems:"center", textAlign:"center", padding:{md:0, xs:"0px 0px 0px 16px"}   }}>
            <Grid item md={3} sm={12} xs={12} sx={{ textAlign:"center",}}>
            <Box sx={{textAlign:{md:"left", sm:"left", xs:"left"}}}> <img src={Alert} alt="motivai"/></Box>
            <Box mt={1} sx={{textAlign:"left"}}>
              <Typography variant="h6" fontFamily={"Red Hat Display"} textAlign={{md:"left", sm:"left", xs:"left"}} fontSize={"20px"} fontWeight={"700"} color={"#000"} >
              Alerts
            </Typography>
            </Box>
            <Box mt={1} sx={{textAlign:"left", width:"221px"}}>
              <Typography variant="text" fontFamily={"Red Hat Display"} textAlign={{md:"left", sm:"left", xs:"left"}} fontSize={"18px"} fontWeight={"600"} color={"#000"} >
              Alert users with anticipative risks while driving.
              </Typography>
            </Box>
            </Grid>
            <Grid item md={3} sm={12} xs={12} sx={{width:"500px"}} mt={{md:0, xs:4, sm:4}}>
            <Box sx={{textAlign:{md:"left", sm:"left", xs:"left"}}}> <img src={Insights} alt="motivai"/></Box>
            <Box mt={1} sx={{textAlign:"left"}}>
              <Typography variant="h6" fontFamily={"Red Hat Display"} textAlign={{md:"left", sm:"left", xs:"left"}} fontSize={"20px"} fontWeight={"700"} color={"#000"} >
              Insights
            </Typography>
            </Box>
            <Box mt={1} sx={{textAlign:"left", width:"217px"}}>
              <Typography variant="text" fontFamily={"Red Hat Display"} textAlign={{md:"left", sm:"left", xs:"left"}} fontSize={"18px"} fontWeight={"600"} color={"#000"} >
              Analyze driving behavior of each individual.
              </Typography>
            </Box>
            </Grid>
            <Grid item md={3} sm={12} xs={12} sx={{width:"500px"}} mt={{md:0, xs:4, sm:4}}>
            <Box sx={{textAlign:{md:"left", sm:"left", xs:"left"}}}> <img src={Highscore} alt="motivai"/></Box>
            <Box mt={1} sx={{textAlign:"left"}}>
              <Typography variant="h6" fontFamily={"Red Hat Display"} textAlign={{md:"left", sm:"left", xs:"left"}} fontSize={"20px"} fontWeight={"700"} color={"#000"} >
              Engage
            </Typography>
            </Box>
            <Box mt={1} sx={{textAlign:"left", width:"211px"}}>
              <Typography variant="text" fontFamily={"Red Hat Display"} textAlign={{md:"left", sm:"left", xs:"left"}} fontSize={"18px"} fontWeight={"600"} color={"#000"} >
              Get user ranking based on driving score.
              </Typography>
            </Box>
            </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default HowItWork;
