import React from "react";
import SolutionsTitles from "../components/title";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import img1 from '../../../assets/images/insurancevechicle.png'
import img2 from '../../../assets/images/insuranceradar.png'
import img3 from '../../../assets/images/ic3.png'
import img4 from '../../../assets/images/ic4.png'

import bgImg from '../../../assets/images/sol2-bg.png'
import imgIcon from '../../../assets/images/solutionsec4-1.png'
import imgMobile from '../../../assets/images/icMobile.png'

import icon1 from '../../../assets/images/RNC-icon.png'
import icon2 from '../../../assets/images/DDC-icon.png'
import icon3 from '../../../assets/images/DCC-icon.png'

import card3 from '../../../assets/images/card-fleet.png'
import card2 from '../../../assets/images/card-cc.png'
import card1 from '../../../assets/images/card-driveWell.png'

import './solutionsPages.css'
import { Link } from "react-router-dom";
import Sstories from "../components/successStories";
import Contact from "../../contact/contact";

const Solutions4 = () => {

    const contentData = [
        
        {
            src: card1,
            text: "What Does It Mean To Drive Well",
            // buttonText: "CASE STUDY",
            // symbol: arrow,
            linkTo: "/what_does_it_mean_to_drive_well_outside_of_any_emergency_situation", // Add the link you want to redirect to
        },
        {
            src: card2,
            text: "Connected Car",
            // buttonText: "WHITE PAPER",
            // symbol: arrow,
            linkTo: "/connected_car", // Add the link you want to redirect to
        },
        {
            src: card3,
            text: "Value for Fleet Management",
            // buttonText: "WHITE PAPER",
            // symbol: arrow,
            linkTo: "/value_for_fleet_management", // Add the link you want to redirect to
        },

    ];


    return (
        <Grid id="fleet" marginTop={{ xs: "100px", sm: "40px", md: "60px" }}>
            <SolutionsTitles />
            <Grid className="container-fluid solution3-paragraph" item xs={12} p={"20px 40px 20px 40px"}>
                <div>Mobile telematics systems are having a dramatic impact on the automotive industry. And for good reason: unlike the rather stagnant traditional method of measuring how a driver drives — using generic and dated information associated with a driver’s age, gender, even marital status — usage-based insurance (or UBI) uses a person’s actual driving behavior to determine an accurate and fair insurance premium.</div>
                <div style={{ marginTop: "10px" }}>It’s an approach that has clients and insurance companies — at least, those who have adopted and implemented coherent mobile telematics programs very excited. In fact, it’s been a huge boon for young drivers, who for years even generations have been unfairly burdened with hefty monthly insurance payments.
                But it’s not just consumers who are able to benefit from the growing popularity of automotive insurance telematics and usage-based insurance. Insurance companies are also setting themselves up for a profit gain, for several simple reasons:
                </div>
                {/* <div style={{ marginTop: "10px" }}>Then, of course, there are the rewards for customers who do sign up for a mobile telematics program. So long as they drive safe, they’ll see their premiums go down, giving them a feeling that their insurance provider really cares about rewarding them for safe on-road behavior. The relationship that emerges between the insurance provider and the customer will be closer and more intimate, making a safe driver less likely to sever their ties with their provider in favor of another automotive insurance company.</div> */}
            </Grid>
            <Grid container sx={{ padding: { md: "20px 25px 0px 35px", sm:"20px 25px 0px 35px", xs:"0px 25px 0px 35px"}}} >
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <Box
                    component="img"
                    src={img1}
                    alt="InsuranceTopImg"
                    sx={{ width: { xs: "100%", sm: "98%" }, display:{xs:"none", sm:"block"} }}
                ></Box>
                </Grid>
                <Grid
                item
                sm={6}
                md={6}
                lg={6}
                xl={6}
                sx={{ display: { xs: "block", sm: "flex" }, justifyContent: "right", }}
                >
                <Box
                    component="img"
                    src={img2}
                    alt="InsuranceTopRightImg"
                    sx={{ width: { xs: "100%", sm: "98%" } }}
                ></Box>
                </Grid>
            </Grid>
            <Grid xs={12} sx={{ padding: "40px 40px 0px 40px" }} className="container-fluid">
                <Typography sx={{ fontFamily: 'Red Hat Display', fontSize: "20px", fontWeight: 700, textAlign: "left", lineHeight:"24px" }}>Mobile telematics allows insurers to learn more about their customers</Typography>
            </Grid>
            <Grid className="container-fluid solution3-paragraph" item xs={12} p={"0px 40px 0px 40px"}>
                <div style={{ marginTop: "10px" }}>It used to be that, in the best-case scenario, a client signed on with an insurance provider and quietly went about paying their premiums for years. Their lives changed and, to some extent, the insurance company learned about this and made necessary adjustments to their premium. But it’s not as if the insurance company really understood their clients or had a clear picture of how they drove — unless they faced a torrent of speeding tickets and traffic accidents.</div>
                <div style={{ marginTop: "10px" }}>But with the introduction of both mobile and device based telematics technology, that’s completely different. By leveraging a smartphone or installing automotive telematics in a vehicle, an automotive insurance provider can, in just a day or two, acquire a pretty accurate picture of a client’s on-road driving behavior. Insurers can see how fast they drive, how quick they take corners, how slowly they brake, when they drive and where they drive.</div>
                <div style={{ marginTop: "10px" }}>That’s hugely useful information on a client-to-client basis. But it’s also useful when considered in a collective way. By collecting all of the information related to a large pool of clients, insurers can start to understand how drivers behave at certain times of the day, in metropolitan or rural environments, even while driving specific types of vehicles. And that information can be collected and analyzed in real time — not just once or twice a year.</div>
            </Grid>
            <Grid xs={12} sx={{ padding: "40px 40px 0px 40px" }} className="container-fluid">
                <Typography sx={{ fontFamily: 'Red Hat Display', fontSize: "20px", fontWeight: 700, textAlign: "left", lineHeight:"24px" }}>Mobile telematics is cost-efficient</Typography>
            </Grid>
            <Grid className="container-fluid solution3-paragraph" item xs={12} p={"0px 40px 0px 40px"}>
                <div style={{ marginTop: "10px" }}>For the past few years, most insurance companies taking a lead role in the development of automotive telematics and usage-based insurance have used small devices that connect to a vehicle’s OBD-II (or diagnostic) port to monitor driver behavior and craft a more accurate picture of how a client acts on the road.</div>
            </Grid>
            <Grid xs={12} sx={{ padding: "40px 40px 40px 40px" }} className="container-fluid">
                <img src={imgIcon} alt="ICicon" width={"100%"}/>
            </Grid>
            <Grid className="container-fluid solution3-paragraph" item xs={12} p={"0px 40px 0px 40px"}>
                <div style={{ marginTop: "10px" }}>For the past few years, most insurance companies taking a lead role in the development of automotive telematics and usage-based insurance have used small devices that connect to a vehicle’s OBD-II (or diagnostic) port to monitor driver behavior and craft a more accurate picture of how a client acts on the road.</div>
                <div style={{ marginTop: "10px" }}>The rapid emergence of smartphones — no longer an expensive technology reserved for the very rich — has made mobile telematics the new champion of usage-based insurance. Besides the fact that just about everyone has one, smartphones are also a lucrative tool for insurance companies because they contain sensors that give insurance providers access to measurements like distance traveled and speed. They can even help insurance providers determine who’s driving (the owner of the vehicle or someone else), how fast the driver is taking turns and braking, and where and when the trip is taking place.</div>
                <div style={{ marginTop: "10px" }}>In the end, the smartphone gives insurance providers cheap and easy access to a wealth of accurate data, helping them bring usage-based insurance options to regions and people previously unfamiliar with the technology.</div>
            </Grid>
            <Grid container padding={"30px 25px 0px 35px"}>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <Box
                    component="img"
                    src={img3}
                    alt="InsuranceTopImg"
                    sx={{ width: { xs: "100%", sm: "98%" }, display:{xs:"block", sm:"block"} }}
                ></Box>
                </Grid>
                <Grid
                item
                sm={6}
                md={6}
                lg={6}
                xl={6}
                sx={{ display: { xs: "none", sm: "flex" }, justifyContent: "right", }}
                >
                <Box
                    component="img"
                    src={img4}
                    alt="InsuranceTopRightImg"
                    sx={{ width: { xs: "100%", sm: "98%" } }}
                ></Box>
                </Grid>
            </Grid>
            <Grid xs={12} sx={{ padding: "40px 40px 0px 40px" }} className="container-fluid">
                <Typography sx={{ fontFamily: 'Red Hat Display', fontSize: "20px", fontWeight: 700, textAlign: "left", lineHeight:"24px", }}>Mobile Telematics systems keeps insurers ahead of the competition</Typography>
            </Grid>
            <Grid className="container-fluid solution3-paragraph" item xs={12} p={"0px 40px 0px 40px"}>
                <div style={{ marginTop: "10px" }}>What do you get with an insurance program that uses the technology you already own (a smartphone) and that rewards clients for driving safer? That’s right — a clear, tangible reason to abide by the rules of the road. It’s a system that effectively rewards the road’s safest drivers with lower insurance premiums. At the same time, it lets insurance providers see who really poses a threat to other drivers, allowing them the option to increase those customers’ premiums while lowering those of safer drivers.</div>
            </Grid>
            <Grid xs={12} sx={{ padding: "40px 40px 0px 40px" }} className="container-fluid">
                <img src={imgMobile} alt="ICmobile" width={"100%"}/>
            </Grid>
            <Grid className="container-fluid solution3-paragraph" item xs={12} p={"10px 40px 0px 40px"}>
                <div style={{ marginTop: "10px" }}>In the end, mobile telematics with the rich insight from mobile data analytics lets insurance companies build more competitive pricing and premiums, giving them an advantage over other automotive insurance providers. Additionally, the collection of a wealth of data allows insurance companies that participate in UBI programs the opportunity to see how their pricing structure will build and change over time.</div>
            </Grid>
            <Box id="contact" sx={{marginLeft:{md:"40px", sm:"0px", xs:"10px"}}}><Contact /></Box>
            <Box sx={{ padding:"10px 20px"}}><Sstories contentData={contentData} /></Box>
        </Grid>
    )
}


export default Solutions4