import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import image1 from "../../../assets/images/sol-dashboard 1.jpg";
import image2 from "../../../assets/images/sol-dashboard 2.jpg";
import image3 from "../../../assets/images/sol-dashboard 3.jpg";
import image4 from "../../../assets/images/sol-dashboard 4.png";
import image5 from "../../../assets/images/sol-dashboard 5.jpg";
import image6 from "../../../assets/images/sol-dashboard 6.jpg";
import image7 from "../../../assets/images/sol-dashboard 7.jpg";
import "../Software.css";
const SoftwareL = () => {
  return (
    <div
      style={{ backgroundColor: "#F4F4F4", paddingBottom: "60px" }}
      id="softwarel"
    >
      <Grid
        container
        spacing={3}
        style={{ paddingLeft: "30px", paddingRight: "50px" }}
      >
        <Grid item xs={12}>
          <Typography
            variant="h5"
            style={{ textAlign: "center", fontSize:"20px", fontWeight: 800 }}
            className="head"
          >
            Dashboard View
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <div style={{ position: "relative" }}>
                <img
                  src={image1}
                  alt="dashboard1"
                  style={{ width: "100%", height: "300px" }}
                />
                <Box className='graphText'
                  sx={{
                    fontSize:"14px",
                    fontWeight:"400",
                    position: "absolute",
                    top: "4%", // Adjust the vertical position as needed
                    left:{md:"4%", sm:"0%", xs:"4%"} , // Adjust the horizontal position as needed
                    transform: "translate(-4%, -4%)",
                    fontFamily:"Red Hat Display", 
                    color: "black", // Adjust the text color
                    padding: "10px", // Adjust padding as needed
                  }}
                >
              Safety Category
                </Box>
              </div>
            </Grid>
            <Grid item xs={12} sm={4}>
              <img
                src={image2}
                alt="dashboard2"
                style={{ width: "100%", height: "322px" }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <div style={{ position: "relative" }}>
                <img
                  src={image3}
                  alt="dashboard1"
                  style={{ width: "100%", height: "322px" }}
                />
                <Box className='graphText'
                  sx={{
                    fontSize:"14px",
                    fontWeight:"400",
                    position: "absolute",
                    top: "0%", // Adjust the vertical position as needed
                    left: {md:"0%", sm:"0%", xs:"4%"}, // Adjust the horizontal position as needed
                    fontFamily:"Red Hat Display",
                    color: "black", // Adjust the text color
                    padding: "10px", // Adjust padding as needed
                  }}
                >
              Safety Category Rides
                </Box>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
          <Grid item xs={12} sm={5}>
              <div style={{ position: "relative" }}>
                <img
                  src={image4}
                  alt="dashboard1"
                  style={{ width: "100%", height: "322px", }}
                />
                <Box className='graphText'
                  sx={{
                    fontSize:"14px",
                    fontWeight:"400",
                    position: "absolute",
                    top: "0%", // Adjust the vertical position as needed
                    left: {md:"0%", sm:"0%", xs:"4%"}, // Adjust the horizontal position as needed
                    fontFamily:"Red Hat Display",
                    color: "black", // Adjust the text color
                    padding: "4px 10px 15px 10px", // Adjust padding as needed
                  }}
                >
           Risk Distribution Graph
                </Box>
              </div>
            </Grid>
            <Grid item xs={12} sm={7}>
            <div style={{ position: "relative", }}>
                <img
                  src={image5}
                  alt="dashboard1"
                  style={{ width: "100%", height: "322px" }}
                />
                <Box className='graphText'
                  sx={{
                    fontSize:"14px",
                    fontWeight:"400",
                    position: "absolute",
                    top: "-0.5%", // Adjust the vertical position as needed
                    left: {md:"4%", sm:"0%", xs:"4%"}, // Adjust the horizontal position as needed
                 fontFamily:"Red Hat Display",
                    color: "black", // Adjust the text color
                    padding: "10px", // Adjust padding as needed
                  }}
                >
          Trip Risk Chart
                </Box>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
            <div style={{ position: "relative" }}>
                <img
                  src={image6}
                  alt="dashboard1"
                  style={{ width: "100%", height: "322px" }}
                />
                <Box className='graphText'
                  sx={{
                    fontSize:"14px",
                    fontWeight:"400",
                    position: "absolute",
                    top: "0%", // Adjust the vertical position as needed
                    left: {md:"0%", sm:"0%", xs:"4%"}, // Adjust the horizontal position as needed
                 fontFamily:"Red Hat Display",
                    color: "black", // Adjust the text color
                    padding: "10px", // Adjust padding as needed
                  }}
                >
         Alert’s Risk Value Summary
                </Box>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
            <div style={{ position: "relative" }}>
                <img
                  src={image7}
                  alt="dashboard1"
                  style={{ width: "100%", height: "322px" }}
                />
                <Box className='graphText'
                  sx={{
                    fontSize:"14px",
                    fontWeight:"400",
                    position: "absolute",
                    top: "0%", // Adjust the vertical position as needed
                    left: {md:"4%", sm:"0%", xs:"4%"}, // Adjust the horizontal position as needed
                 fontFamily:"Red Hat Display",
                    color: "black", // Adjust the text color
                    padding: "10px", // Adjust padding as needed
                  }}
                >
    Driving Style Estimation
                </Box>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default SoftwareL;
