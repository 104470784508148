import React from 'react'
import img1 from '../../assets/images/homeSection4-CarImage.png'
import bgImg from '../../assets/images/phoneBg-Image.png'
import bgImg1 from '../../assets/images/iPhoneVFMmobile.png'
import Grid from '@mui/material/Grid';
import { Link } from 'react-router-dom'
import { Box, Button } from '@mui/material'

const HomeSection3 = () => {

    const handleScrollTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      };

    return (
        <>
            <div id='section3' style={{ marginTop: "70px" }}>
                <div className='container-fluid main'>
                    <div className='row sub1'>
                        <div className='col-sm-12 content2'><p>Powered by Motiv AI</p></div>
                    </div>

                    <div className='row sub2'>
                        <div className='col-sm-12 content1'>
                            <img src={img1} />
                        </div>
                    </div>

                    <div className='row sub3'>
                        <div className='col-sm-12 content1'>
                            <div className='row'>
                                <div className='col-sm-3'></div>
                                <div className='col-sm-6'> <p>'Unfortunately; what some drivers do, they commit incautious acts but do not have an accident or even a bad surprise, and they take on bad habits in terms of road safety’</p> </div>
                                <div className='col-sm-3'></div>
                            </div>
                        </div>
                    </div>

                    <div className='sub4'>
                        <Grid container>
                            <Grid item xs={12} sm={6} >
                                <Grid container>
                                    <Grid item xs={12} sm={6} display={{ sm: "block", xs: "flex" }} >
                                        <Grid xs={6} sm={0} >
                                            <div className="leftContent">The ultimate driving solution for everyone</div>
                                        </Grid>
                                        <Grid xs={6} sm={3} sx={{textAlign:"end"}}>
                                            <Link to="/software" onClick={handleScrollTop}>
                                                <Button variant="contained" className="more">
                                                    Know More
                                                </Button>
                                            </Link>
                                        </Grid>
                                    </Grid>
                                    <Grid item sm={6} />
                                </Grid>

                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <Grid container>
                                    <Grid item xs={12} sm={12} className='box'>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <div className='head'>Insurance</div>
                                            <div className='content'>
                                                Digital personal and commercial lines solutions enabling more precise underwriting and optimized pricing.
                                            </div>
                                        </div>
                                    </Grid>
                                    <div className="line3"></div>
                                    <Grid item xs={12} sm={12} className='box'>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <div className='head'>New mobility</div>
                                            <div className='content'>
                                                Pattern profiling for greater risk control. Usage-based solutions for extended flexibility and revenue.
                                            </div>
                                        </div>
                                    </Grid>
                                    <div className="line3"></div>
                                    <Grid item xs={12} sm={12} className='box'>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <div className='head'>Fleet</div>
                                            <div className='content'>
                                                Proactive risk management for incident prevention, energy consumption, and CO2 emission control.
                                            </div>
                                        </div>
                                    </Grid>
                                    <div className="line3"></div>
                                    <Grid item xs={12} sm={12} className='box' sx={{paddingBottom:{xs:"15px",sm:"15px"}}}>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <div className='head'> Automotive</div>
                                            <div className='content'>
                                                Tailored subscription-based models and loyalty programs with instant profiling on crash risk, CO2, and EV impact.
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Box className='bgImage' sx={{ display :{ md:"block", sm:"none", xs:"none"}}}>
                                <img src={bgImg} />
                            </Box>
                        </Grid>
                    </div>


                </div>
            </div>
            <Grid container sx={{ display: {md:"none", sm:'block', xs:"block", textAlign:"center", justifyContent:"center"}}}>
                <Box sx={{padding:"30px 30px 0px 30px"}}>
                    <img src={bgImg1} alt='bgImg1' style={{ width:"250px", height:"500px"}}/>
                </Box>
            </Grid>
        </>
    )
}

export default HomeSection3
