import React from "react";
import { AppBar, Box, Breadcrumbs, Toolbar, Typography } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import "./banner.css";
import HomeIcon from '@mui/icons-material/Home';
import { useState } from "react";

const cleanAndCapitalize = (str) => {
  const cleanedString = str.replace(/[^a-zA-Z0-9 ]/g, ' ');
  const capitalizedString = cleanedString.replace(/\b\w/g, (match) => match.toUpperCase());
  if (capitalizedString === "Faq") {
    return "FAQ";
  } else if (capitalizedString === "Terms And Conditions") {
    return "Terms & Conditions";
  }
  return capitalizedString;
};

const linkSol = () => {
  // Define the URL you want to link the Solutions segment to
  // For example:
  window.location.href = "/solutions";
};

const Banner = ({ title }) => {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);

  return (
    <Toolbar className="banner">
      <Box display="flex" flexDirection="column" style={{ width: "100%" }}>
        <Typography className="bannerTitle" component="div" gutterBottom>
          {title}
        </Typography>
        {/* <Breadcrumbs aria-label="breadcrumb" separator={<Typography color="black">/</Typography>}>
          <Link color="inherit" to="/" style={{ color: "black", textDecoration: "none" }}>
            <Box display="flex" alignItems="center">
              <HomeIcon style={{ fontSize: "1.5rem", marginRight: "0.5rem" }} />
              Home
            </Box>
          </Link>
          {pathnames.map((name, index) => {
            const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
            const isLast = index === pathnames.length - 1;
            const cleanName = cleanAndCapitalize(name);
            return (
              <React.Fragment key={cleanName}>
                {pathnames.includes("value_for_fleet_management") ||
                pathnames.includes("value_for_motor_insurers") ||
                pathnames.includes("mobile_telematics_providers_are_commonly_seen_as_disruptive_innovators") ||
                pathnames.includes("insurance_companies_are_setting_for_a_profit_gain_from_the_mobile_telematics_systems") ||
                pathnames.includes("connected_car") ||
                pathnames.includes("what_does_it_mean_to_drive_well_outside_of_any_emergency_situation") ? (
                  isLast ? (
                    <Typography color="black" style={{ fontWeight: "600", fontSize: "15px" }}>
                      <span style={{ fontWeight: "400", fontSize: "16px", cursor: "pointer" }} onClick={linkSol}>Solutions / </span>{cleanName}
                    </Typography>
                  ) : (
                    <Link color="inherit" to={routeTo}>
                      <span style={{ fontWeight: "400", fontSize: "16px", cursor: "pointer" }} onClick={linkSol}>Solutions / </span>{cleanName}
                    </Link>
                  )
                ) : (
                  isLast ? (
                    <Typography color="black" style={{ fontWeight: "600", fontSize: "15px" }}>
                      {cleanName}
                    </Typography>
                  ) : (
                    <Link color="inherit" to={routeTo}>
                      {cleanName}
                    </Link>
                  )
                )}
              </React.Fragment>
            );
          })}
        </Breadcrumbs> */}
      </Box>
    </Toolbar>
  );
};

export default Banner;
