import React from "react";
import RiskAlertImg from "../../../../assets/images/riskalertsIphonesstraightview.png";
import { Box, Container, Grid, Typography } from "@mui/material";

const data = [
  {
    imageSrc: RiskAlertImg,
    title: "About risk alerts",

    
    description_2:
      'Detection of entry into risk triangle, and action for a rapid exit: "undo unsafe act"',
  },
];

const RiskAlert = () => {
  return (
    <div style={{ padding: "0rem 0 0rem 0" }}>
      <div class="container">
        <Container>
          {data.map((item, index) => (
            <Grid container spacing={2} key={index}>
              
              {/* Left: Title and Paragraph  */}
              <Grid
                item
                xs={12}
                md={6}
                display={"flex"}
                alignItems="center"
               
              >
                <div style={{ flex: 1, padding: "1rem" }}>
                  <Typography
                    sx={{
                      color: "#000",
                      fontSize: "20px",
                      fontWeight: "600",
                      fontFamily: "Red Hat Display",
                    }}
                    variant="h4"
                    gutterBottom
                    className="RiskAlertTitle"
                  >
                    {item.title}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#000",
                      fontSize: "18px",
                      fontWeight: "400",
                      fontFamily: "Red Hat Display",
                      fontStyle:"italic",
                      lineHeight:"1.1"
                    }}
                    variant="body1"
                    gutterBottom
                    className="RiskAlertTitleDesc_2"
                  >
                    {item.description_2}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#000",
                      textAlign: "left",
                      fontSize: "16px",
                      fontWeight: "4 00",
                      fontFamily: "Red Hat Display",
                    }}
                    variant="body2"
                    className="RiskAlertTitleDesc"
                  >
                    Continuous monitoring of the value of risk so as never to enter, when possible into risk triangle, to do this, we define a maximum authorized risk (lower than the high risk which corresponds to entering triangle. The risk is referred to as 'risk target'. If we manage not to exceed risk target, then we don't enter into risk triangle and we do not commit an unsafe act. <span style={{fontSize:"14px",fontWeight:"600"}}>This is the second level of anticipation.</span>
                  </Typography>
                </div>
              </Grid>


              <Grid item xs={12} md={6}>
                <Box
                  elevation={3}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <div style={{ flex: 1 }}>
                    <img
                      src={item.imageSrc}
                      alt="Image"
                      style={{
                        maxWidth: "80%", // Make the image responsive
                        height: "auto", // Maintain aspect ratio
                      }}
                    />
                  </div>
                </Box>
              </Grid>

              {/* Right: Image */}
            </Grid>
          ))}
        </Container>
      </div>
    </div>
  );
};

export default RiskAlert;
