import React from "react";
import TitleComp from "../components/titleComponent.jsx/headerTitleComp";
import PrivacyParagraph from "./paragraphComponent";
import Banner from "../components/banner/banner";
import bannerimage from "../../assets/images/tc-banner.jpg";




const Terms = () => {
  return (
    <div style={{ marginTop: "64px" ,marginBottom:"80px"}}>
      <div style={{ width: "100%" }}>
        <img
          src={bannerimage}
          alt="banner"
          className="img-fluid"
          width="100%"
          style={{ height: "400px", objectFit: "cover" }}
        />
      </div>
      <Banner title="" />
      <TitleComp title="Terms & Conditions" />
      <div>
    
          <PrivacyParagraph/>
 
      </div>
    </div>
  );
};

export default Terms;
