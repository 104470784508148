import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import {
  Button,
  CardActionArea,
  CardActions,
  ButtonGroup,
} from "@mui/material";
import { Grid } from "@mui/material";
import "./index.css";
import Banner from "../components/banner/banner";
import image1 from "../../assets/images/card-fleet.png";
import image2 from "../../assets/images/card-vfmi.png";
import image3 from "../../assets/images/card-mtp.png";
import image4 from "../../assets/images/card-iamt.png";
import image5 from "../../assets/images/card-driveWell.png";
import image6 from "../../assets/images/card-cc.png";

import { Link } from "react-router-dom";
import bannerimage from "../../assets/images/SolutionBanner.png";

import video1 from '../../assets/videos/solutions-video.mp4';

// function toCamelCase(str) {
//   return str
//     .toLowerCase()
//     .replace(/[^a-zA-Z0-9]+(. )/g, (m, chr) => chr.toUpperCase());
// }

export default function SolutionsMaster() {
  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);

    // Optionally, you can also scroll to the top when a link is clicked
    const scrollToTop = () => {
      window.scrollTo(0, 0);
    };

    // Attach the scroll to top function to all links with class "scroll-to-top"
    const links = document.querySelectorAll(".scroll-to-top");
    links.forEach((link) => {
      link.addEventListener("click", scrollToTop);
    });

    // Clean up event listeners when the component unmounts
    return () => {
      links.forEach((link) => {
        link.removeEventListener("click", scrollToTop);
      });
    };
  }, []);

  const contentData = [
    {
      src: image1,
      text: "Value for Fleet Management",
      // buttonText: "BLOG",
      link: "/value_for_fleet_management",
    },
    {
      src: image2,
      text: "Value for Motor Insurers",
      // buttonText: "CASE STUDY",
      link: "/value_for_motor_insurers",
    },
    {
      src: image3,
      text: "Mobile Telematics Providers",
      // buttonText: "WHITE PAPER",
      link: "/mobile_telematics_providers_are_commonly_seen_as_disruptive_innovators",
    },
    {
      src: image4,
      text: "Insurance and Mobile Telematics",
      // buttonText: "BLOG",
      link: "/insurance_companies_are_setting_for_a_profit_gain_from_the_mobile_telematics_systems",
    },
    {
      src: image5,
      text: "What Does It Mean To Drive Well",
      // buttonText: "WHITE PAPER",
      link: "/what_does_it_mean_to_drive_well_outside_of_any_emergency_situation?",
    },
    {
      src: image6,
      text: "Connected Car",
      // buttonText: "CASE STUDY",
      link: "/connected_car",
    },
  ];
  // const categories = ["All", "CASE STUDY", "WHITE PAPER", "BLOG"];

  // const itemsPerPage = 9;

  // const [filteredData, setFilteredData] = useState(contentData);
  // const [activeFilter, setActiveFilter] = useState("All");
  // const [currentPage, setCurrentPage] = useState(1);

  // const handleFilter = (filter) => {
  //   setActiveFilter(filter);
  //   if (filter === "All") {
  //     setFilteredData(contentData);
  //   } else {
  //     const filteredContent = contentData.filter(
  //       (content) => content.buttonText === filter
  //     );
  //     setFilteredData(filteredContent);
  //   }
  //   setCurrentPage(1);
  // };

  // const startIndex = (currentPage - 1) * itemsPerPage;
  // const endIndex = startIndex + itemsPerPage;
  // const displayedData = filteredData.slice(startIndex, endIndex);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div style={{ width: "100%", marginTop: "64px" }}>
        <img
          src={bannerimage}
          alt="banner"
          className="img-fluid"
          width="100%"
          style={{ height: "400px" }}
        />
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "40px",
          marginBottom: "40px",
          padding: "20px"
        }}
      >
        <Grid container spacing={{ xs: 1, sm: 2, md: 5 }}>
          {contentData.map((content, index) => (
            <Grid item xs={12} sm={4} md={4} lg={4} marginTop={{xs: 2, sm: 0}} key={index}>
              <Link to={content.link} style={{ textDecoration: "none" }} className="scroll-to-top">
                <Card
                  sx={{
                    borderRadius: "10px",
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",

                  }}
                >
                  <CardActionArea >
                    <img
                      src={content.src}
                      alt={`Img ${index + 1}`}
                      style={{
                        height: "291px",
                        width: "100%",
                        objectFit: "cover",
                      }}
                      class='img-fluid'
                    />
                  </CardActionArea>
                </Card>
                <Typography sx={{ fontSize: { xs: "16px",md: "18px", lg: "17px" }, fontFamily: 'Red Hat Display', color:"black",fontWeight: '600', marginTop: '10px' }}>
                  {content.text}
                </Typography>
              </Link>
            </Grid>
          ))}
        </Grid>
      </div>

      {/* pagination */}
      {/* <div className="solutions-pagination">
          <Stack spacing={2} alignItems={"center"} mt={7} mb={7}>
            <Pagination
              count={pageCount}
              size={windowWidth <= 320 ? "small" : "large"}
              page={currentPage}
              onChange={(event, value) => setCurrentPage(value)}
              classes={{
                root: "custom-pagination-root",
                ul: "custom-pagination-ul",
              }}
              previousIconButtonProps={{ className: "custom-pagination-arrow" }}
              nextIconButtonProps={{ className: "custom-pagination-arrow" }}
              boundaryCount={0}
            />
          </Stack>
        </div> */}

    </>
  );
}
