import React from "react";
import SolutionsTitles from "../components/title";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import img1 from '../../../assets/images/dw1.png'
import img2 from '../../../assets/images/dw2.png'

import bgImg from '../../../assets/images/sol2-bg.png'

import icon1 from '../../../assets/images/RNC-icon.png'
import icon2 from '../../../assets/images/DDC-icon.png'
import icon3 from '../../../assets/images/DCC-icon.png'

import card1 from '../../../assets/images/card-cc.png'
import card2 from '../../../assets/images/card-fleet.png'
import card3 from '../../../assets/images/card-vfmi.png'

import './solutionsPages.css'
import { Link } from "react-router-dom";
import Sstories from "../components/successStories";
import Contact from "../../contact/contact";

const Solutions5 = () => {

    const contentData = [


        {
            src: card1,
            text: "Connected Car",
            // buttonText: "WHITE PAPER",
            // symbol: arrow,
            linkTo: "/connected_car", // Add the link you want to redirect to
        },
        {
            src: card2,
            text: "Value for Fleet Management",
            // buttonText: "WHITE PAPER",
            // symbol: arrow,
            linkTo: "/value_for_fleet_management",
        },
        {
            src: card3,
            text: "Value for Motor Insurers",
            // buttonText: "CASE STUDY",
            // symbol: arrow,
            linkTo: "/value_for_motor_insurers",
        },

    ];


    return (
        <Grid id="fleet" marginTop={{ xs: "100px", sm: "40px", md: "60px" }}>
            <SolutionsTitles />
            <Grid className="container-fluid solution3-paragraph" item xs={12} p={"10px 40px 20px 40px"}>
                <div>This question was initially asked by insurers and driving psychologists, and it is now coming to cars/ trucks/ 2wheelers manufacturers to offer non-emergency driving assistance systems</div>
                <div style={{ marginTop: "10px" }}>
                    The idea of reformulating this question from perspective of prudence and have chosen to estimate in real time level of caution (or prudence) of a driving behavior regarding context. Notion of context is essential and is used to define prudence as ‘the adequacy of the behavior to the context, without necessarily knowing the whole context or all the possible consequences of a behavior’.
                </div>
                <div style={{ marginTop: "10px" }}>
                    The approach seems to be more operational than that consisting in seeking to know the whole context and knowing how to predict consequences of any action, an approach which is somewhat the philosophy of a lot of work on autonomous vehicle.
                </div>
                <div style={{ marginTop: "10px" }}>
                    Unfortunately, this last solution obviously leads to vehicles overloaded with sensors and to road infrastructures that are overpriced (since we have to know everything), and to increasing on-board computing power (since we have to understand everything), putting the fruits of this work out of the market because it is too expensive.
                </div>
                <div style={{ marginTop: "10px" }}>
                    Such an approach can only be applied to public transport systems, since the purchase or rental of such a vehicle is not an option. It is still necessary that the flow of people transported make it possible to make investment profitable over an acceptable period, which is not yet the case to date.
                </div>
            </Grid>
            <Grid container padding={"30px 25px 0px 35px"}>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <Box
                    component="img"
                    src={img1}
                    alt="InsuranceTopImg"
                    sx={{ width: { xs: "100%", sm: "98%" }, display:{xs:"none", sm:"block"} }}
                ></Box>
                </Grid>
                <Grid
                item
                sm={6}
                md={6}
                lg={6}
                xl={6}
                sx={{ display: { xs: "block", sm: "flex" }, justifyContent: "right", }}
                >
                <Box
                    component="img"
                    src={img2}
                    alt="InsuranceTopRightImg"
                    sx={{ width: { xs: "100%", sm: "98%" } }}
                ></Box>
                </Grid>
            </Grid>
            <Grid xs={12} sx={{ padding: "40px 40px 0px 40px" }} className="container-fluid">
                <Typography sx={{ fontFamily: 'Red Hat Display', fontSize: "20px", fontWeight: 700, textAlign: "left", lineHeight:"24px" }}>Detection of entry into risk triangle, and action for a rapid exit: “undo unsafe act”</Typography>
            </Grid>
            <Grid className="container-fluid solution3-paragraph" item xs={12} p={"10px 40px 0px 40px"}>
                <div style={{ marginTop: "10px" }}>
                    Continuous monitoring of the value of risk so as never to enter, when possible into risk triangle, to do this, we define a maximum authorized risk (lower than the high risk which corresponds to entering triangle. The risk is referred to as ‘risk target’. If we manage not to exceed risk target, then we don’t enter into risk triangle and we do not commit an unsafe act. This is the second level of anticipation.

                </div>
                <div style={{ marginTop: "10px" }}>
                    Note: Conditions on real roads may be complex, and sometimes it is not possible to avoid entering triangle, for example, vehicle should slow down to stay prudent but a crazy vehicle appeared behind and may hit rear of the car if braking is done at right level, this idea is to be aware of it and if obliged to enter triangle, then find a quick way to exit.</div>
            </Grid>
            <Box id="contact" sx={{ marginLeft: { md: "40px", sm: "0px", xs: "10px" } }}><Contact /></Box>
            <Box sx={{ padding: "0px 20px" }}><Sstories contentData={contentData} /></Box>
        </Grid>
    )
}


export default Solutions5