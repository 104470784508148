import { Button, Card, CardActionArea, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import './ss.css'
import '../../home/Home.css'

import { Link } from "react-router-dom";


const Sstories = ({ contentData }) => {

    const handleScrollTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      };

      useEffect(() => {
        // Scroll to the top when the component mounts
        window.scrollTo(0, 0);

        // Optionally, you can also scroll to the top when a link is clicked
        const scrollToTop = () => {
            window.scrollTo(0, 0);
        };

        // Attach the scroll to top function to all links with class "scroll-to-top"
        const links = document.querySelectorAll(".scroll-to-top");
        links.forEach(link => {
            link.addEventListener("click", scrollToTop);
        });

        // Clean up event listeners when the component unmounts
        return () => {
            links.forEach(link => {
                link.removeEventListener("click", scrollToTop);
            });
        };
    }, []);


    return (
        <div id='section5' >
        <div class='container-fluid main'>
            <Grid container className="sub1" marginLeft="10px">
                <Grid item xs={6} sm={6} className='content2' display={"flex"} justifyContent={"left"} alignItems={"center"}><p style={{whiteSpace:"nowrap"}}>Success Stories</p></Grid>
                <Grid item xs={6} sm={6} className='content2' display={"flex"} justifyContent={"right"} alignItems={"center"}>
                    <Link to="/solutions" style={{ textDecoration: "none" }} onClick={handleScrollTop} className="scroll-to-top" >
                    <Button variant="contained"   className="viewmore"  sx={{position:"relative",bottom:"5px", whiteSpace:"nowrap"}}>
                        View More
                    </Button>
                </Link></Grid>
            </Grid>
            <div class='sub1' style={{ display: "flex", justifyContent: "center", padding: "10px" }}>
                <Grid
                    container
                    spacing={2}
                    style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "50px" }}
                >
                    {contentData.map((content, index) => (
                        <Grid item xs={12} sm={4} md={4} lg={4} key={index} sx={{ marginBottom:{ md:"0px", sm:"10px", xs:"10px"}}}>
                            <Link to={content.linkTo} style={{ textDecoration: "none" }} className="scroll-to-top">
                                <Card
                                    sx={{
                                        borderRadius: "10px",
                                        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                                        
                                    }}
                                >
                                    <CardActionArea >
                                        <img
                                            src={content.src}
                                            alt={`Img ${index + 1}`}
                                            style={{
                                                height: "289px",
                                                width: "100%",
                                                objectFit: "cover",
                                            }}
                                            class='img-fluid'
                                        />

                                        {/* <CardContent>
                                            <CardActions
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    padding: "0px",
                                                }}
                                            >
                                                <Button
                                                    size="small"
                                                    sx={{
                                                        backgroundColor: "#FFCF24",
                                                        color: "#000000",
                                                        fontFamily: "segoe ui",
                                                        fontWeight: "600",
                                                        fontSize: "12px",

                                                        alignContent: "center",
                                                    }}
                                                >
                                                    {content.buttonText}
                                                </Button>
                                                <p
                                                    style={{
                                                        fontFamily: "Segoe UI",
                                                        fontSize: "14px",
                                                        fontWeight: "400",
                                                        color: "#545252",
                                                        lineHeight: "28px",
                                                        alignContent: "center",
                                                    }}
                                                >
                                                    {content.date}
                                                </p>
                                            </CardActions>
                                            <Typography variant="body2" sx={{ color: "#000000", fontFamily: 'Segoe UI', fontSize: { md: "18px", lg: "15px" }, fontWeight: '600', marginTop: '10px' }}>
                                                {content.text}
                                            </Typography>
                                        </CardContent> */}

                                    </CardActionArea>
                                </Card>
                                <Typography className="cardtypo" sx={{fontSize: { md: "18px", lg: "17px" }, fontWeight: '600', marginTop: '10px' }}>
                                    {content.text}
                                </Typography>
                            </Link>
                        </Grid>
                    ))}
                </Grid>
            </div>
        </div>
    </div>


        // <Grid mt={5} mb={3} className="container-fluid main" id="ss">
        //     <Grid container className="sub1" marginLeft="10px">
        //         <Grid item xs={6} sm={6} className='content2' display={"flex"} justifyContent={{ xs: "center", sm: "left" }} alignItems={"center"}><p style={{whiteSpace:"nowrap"}}>Success Stories</p></Grid>
        //         <Grid item xs={6} sm={6} className='content2' display={"flex"} justifyContent={{ xs: "center", sm: "right" }} alignItems={"center"}>
        //             <Link to="/solutions" style={{ textDecoration: "none" }} className="scroll-to-top" >
        //                 <Button variant="contained" className="viewmore">
        //                     View More
        //                 </Button>
        //             </Link></Grid>
        //     </Grid>
        //     <div class='sub1' style={{ display: "flex", justifyContent: "center", padding: "10px" }}>
        //         <Grid
        //             container
        //             spacing={2}
        //             style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
        //         >
        //             {contentData.map((content, index) => (
        //                 <Grid item xs={8} sm={4} md={4} lg={4} key={index}>
        //                     <Link to={content.linkTo} onClick={handleScrollTop} style={{ textDecoration: "none" }} className="scroll-to-top">
        //                         <Card
        //                             sx={{
        //                                 borderRadius: "10px",
        //                                 boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                                        
        //                             }}
        //                         >
        //                             <CardActionArea>
        //                                 <img
        //                                     src={content.src}
        //                                     alt={`Img ${index + 1}`}
        //                                     style={{
        //                                         height: "291px",
        //                                         width: "100%",
        //                                         objectFit: "cover",
        //                                     }}
        //                                     class='img-fluid'
        //                                 />
        //                             </CardActionArea>
        //                         </Card>
        //                         <Typography className="cardtypo" sx={{ fontSize: { md: "18px", lg: "17px" }, fontWeight: '600', marginTop: '10px' }}>
        //                             {content.text}
        //                         </Typography>
        //                     </Link>
        //                 </Grid>
        //             ))}
        //         </Grid>
        //     </div>
        // </Grid>
    )
}

export default Sstories