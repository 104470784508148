import React from 'react'
import HomeSection1 from './HomeSection1'
import HomeSection2 from './HomeSection2'
import HomeSection3 from './HomeSection3'
import IsoCertification from './IsoCertification'
import SliderHome from './slider'
import HomeSection4 from './HomeSection4'
import HomeSection5 from './HomeSection5'
import Contact from '../contact/contact'
import HomeSection6 from './HomeSection6'


const HomeMaster = () => {
    return (
        <>
            <HomeSection1 />
            <HomeSection2 />
            <HomeSection3 />
            <IsoCertification />
            <SliderHome />
            <HomeSection4 />
            <HomeSection5 />
            <Contact />
            <HomeSection6 />
        </>
    )
}

export default HomeMaster