import React from 'react';
import Grid from '@mui/material/Grid';
import { Box, Typography } from '@mui/material';

import Img7 from '../../../assets/images/motivai.png';
import Img8 from '../../../assets/images/motivaiNEA.png';
import Img9 from '../../../assets/images/sol-last.png';

const Software8 = () => {
  return (
    <Grid container sx={{padding:{xs:"0",sm:"0",md:"100px",lg:"100px",xl:"100px"}}}>
      <Grid item xs={12} md={6} lg={6}  style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '1rem',
          }}>
        <img src={Img9} alt='Image 8' style={{ width: '300px', height: 'auto', }} />
      </Grid>
      <Grid item xs={12} md={6} lg={6}  style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }} >
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '1rem',
            marginTop:"20px"
          }}
        >
          <Typography variant='h2' style={{ textAlign: 'center', marginBottom: '1rem',fontSize:'20px',fontWeight:'500', color:"#fff", fontFamily:'Red Hat Display' }}>
            No hardware&nbsp;  | &nbsp; Easy to Install&nbsp;  | &nbsp; Affordable price
          </Typography>
          <img src={Img7} alt='Image 7' style={{ width: '154px', maxWidth: '154px', height: 'auto' }} />
          <img src={Img8} alt='Image 8' style={{ width: '100%', maxWidth: '562px', height: 'auto', marginTop: '1rem' }} />
        </Box>
      </Grid>
    </Grid>
  );
};

export default Software8;
