import React from 'react';
import isoimg from '../../assets/images/iso.png';
import { Grid } from '@mui/material';

const IsoCertification = () => {
    return (
        <Grid xs={12} id="isoSection" className='container-fluid'>
            <Grid container>
                <Grid item md={1.5} />
                <Grid item sm={8} md={7} xs={12} textAlign={{ sm:"left", xs:"center"}} marginBottom={{ sm:"0px", xs:"20px"}}>
                    <div className='isosubHead'>Motiv AI is <span className='isosubHeadBlue'>ISO 27001</span> Certified</div>
                    <div className='isotext'>We are an automotive software company focusing on Onboard Hybrid Artificial Intelligence Platform for ADAS, Autonomous Driving, Telematics, and Road Safety.</div>
                </Grid>
                <Grid item sm={4} md={2} xs={12} display={"flex"} justifyContent={{ sm: "flex-end", xs: "center" }} ><img src={isoimg} height="140px"/></Grid>
                <Grid item md={1.5} />
            </Grid>
        </Grid>
    );
};

export default IsoCertification;
