import { Button, FormControl, FormLabel, Grid, MenuItem, Stack, TextField, Typography, createTheme, useMediaQuery, Dialog, DialogTitle, DialogContent, DialogActions, Snackbar, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from '@mui/icons-material/Close';
import { Link, useLocation, useNavigate } from "react-router-dom";
import './contact.css'
import ContactFormController from "./controller/solutionFormController";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const Contact = () => {

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        hasApp: '',
        region: '',
        message: ''
    });

    const handleScrollTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      };

    const location = useLocation();

    const [errors, setErrors] = useState({});
    
    const [showThanks, setShowThanks] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
    
        // Convert email to lowercase if it's the email field
        const newValue = name === 'email' ? value.toLowerCase() : value;
    
        setFormData({ ...formData, [name]: newValue });
    };
    

    const handleSubmit = async (e) => {
        e.preventDefault();
        let errors = {};
        if (!formData.name.trim()) {
            errors.name = 'Name is required';
        } else if (!/^[A-Za-z\s]+$/.test(formData.name)) {
            errors.name = 'Enter a valid name';
        }
        if (!formData.email.trim()) {
            errors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            errors.email = 'Enter a valid email';
        }
        if (!formData.hasApp) {
            errors.hasApp = 'Please select an option';
        }
        if (!formData.region) {
            errors.region = 'Please select a region';
        }
        if (formData.message.length === 0) {
            errors.message = '';
        } else if (formData.message.length > 500) {
            errors.message = 'Message should not exceed 500 characters';
        }
        setErrors(errors);

        // If there are no errors, you can submit the form
        if (Object.keys(errors).length === 0) {
            setShowThanks(true);
            setFormData({
                name: '',
                email: '',
                hasApp: '',
                region: '',
                message: ''
            });
            await postFormData();
        }
    };




    const indianStates = [
        'Andaman and Nicobar Islands',
        'Andhra Pradesh',
        'Arunachal Pradesh',
        'Assam',
        'Bihar',
        'Chandigarh',
        'Chhattisgarh',
        'Daman',
        'Delhi',
        'Goa',
        'Gujarat',
        'Haryana',
        'Himachal Pradesh',
        'Jharkhand',
        'Karnataka',
        'Kerala',
        'Lakshadweep',
        'Madhya Pradesh',
        'Maharashtra',
        'Manipur',
        'Meghalaya',
        'Mizoram',
        'Nagaland',
        'Odisha',
        'Puducherry',
        'Punjab',
        'Rajasthan',
        'Sikkim',
        'Tamil Nadu',
        'Telangana',
        'Tripura',
        'Uttar Pradesh',
        'Uttarakhand',
        'West Bengal',
    ];

    // Render the Indian states as MenuItems
    const indianStateOptions = indianStates.map((state, index) => (
        <MenuItem key={index} value={state}>
            {state}
        </MenuItem>
    ));

    const postFormData = async () => {
        var response = await ContactFormController.postUserDetails(formData);
    }

    return (
        <Grid xs={12} mt={5} id="contact" className="container-fluid" p={{ xs: "0px", sm: "0px 0px 40px 0px " }} pt={0} pb={0}>
            <Grid container gap={{ xl: 10, md: 6, sm: 1 }} padding={{ xs: 3, md: 0 }}>
                <Grid
                    item
                    xs={12}
                    md={5.5}
                    className={location.pathname === "/" ? "homeForm" : location.pathname === "/contact" ? "contactForm" :
                        location.pathname === "/software" ? "softwareForm" : location.pathname === "/value_for_fleet_management" ? "fleetForm" :
                            location.pathname === "/value_for_motor_insurers" ? "miForm" :
                                location.pathname === "/mobile_telematics_providers_are_commonly_seen_as_disruptive_innovators" ? "mtForm" :
                                    location.pathname === "/insurance_companies_are_setting_for_a_profit_gain_from_the_mobile_telematics_systems" ? "icForm" :
                                        location.pathname === "/what_does_it_mean_to_drive_well_outside_of_any_emergency_situation" ? "dwForm" :
                    location.pathname === "/connected_car" ? "caForm" : "ccForm"
                }
                    sx={{
                        height: {
                            xs: "480px",
                            sm: "500px",
                            md: "530px",
                            lg: "550px",
                            xl: "650px",
                        },
                        borderRadius: {
                            xs: "20px",
                            md: "20px",
                            lg: "20px",
                        },
                        display: {
                            md: "block",
                            sm: "none",
                            xs: "none"
                        }
                    }}
                ></Grid>

                <Grid item xs={12} md={5.5} className="form-grid" marginTop={{ md: 0, xs: 2 }}>
                    <Grid container className="form-container">
                        <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                            <Grid item style={{ marginBottom: "3%" }} xs={12} className="title">Get in touch with the Motiv AI team</Grid>
                            <Grid item style={{ marginBottom: "3%" }} xs={12}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            label="Your Name *"
                                            margin="dense"
                                            fullWidth
                                            name="name"
                                            value={formData.name}
                                            onChange={handleChange}
                                            error={!!errors.name}
                                            helperText={errors.name}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            label="Company Email *"
                                            margin="dense"
                                            fullWidth
                                            name="email"
                                            value={formData.email}
                                            onChange={handleChange}
                                            error={!!errors.email}
                                            helperText={errors.email}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item style={{ marginBottom: "3%" }} xs={12}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            label="Do you have an app? *"
                                            margin="dense"
                                            fullWidth
                                            select
                                            name="hasApp"
                                            value={formData.hasApp}
                                            onChange={handleChange}
                                            error={!!errors.hasApp}
                                            helperText={errors.hasApp}
                                        >
                                            <MenuItem value="">Select</MenuItem>
                                            <MenuItem value="YES">Yes</MenuItem>
                                            <MenuItem value="NO">No</MenuItem>
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            label="Region *"
                                            margin="dense"
                                            fullWidth
                                            select
                                            name="region"
                                            value={formData.region}
                                            onChange={handleChange}
                                            error={!!errors.region}
                                            helperText={errors.region}
                                            SelectProps={{
                                                MenuProps: {
                                                    PaperProps: {
                                                        sx: {
                                                            maxHeight: 200,
                                                            width: { xs: 40, sm: 250 }
                                                        },
                                                    },
                                                },
                                            }}
                                        >
                                            <MenuItem value="" >Select</MenuItem>
                                            {indianStateOptions}
                                        </TextField>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item style={{ marginBottom: "0%" }} xs={12}>
                                <TextField
                                    label="Your Message"
                                    multiline
                                    rows={4}
                                    margin="dense"
                                    fullWidth
                                    name="message"
                                    value={formData.message}
                                    onChange={handleChange}
                                    error={!!errors.message}
                                    helperText={errors.message}
                                />
                            </Grid>
                            <Grid item style={{ marginBottom: "6%" }} xs={3}><Button className="submit" type="submit">Submit</Button></Grid>
                            <Grid item xs={12} className="formAgree">By clicking submit you consent to sharing your data with Motiv AI for the purposes of contacting you regarding Motiv AI’s product <Link to={"/privacy_policy"} onClick={handleScrollTop} className="formLink scroll-to-top">Privacy Policy</Link></Grid>
                        </form>
                    </Grid>
                </Grid>
            </Grid>
            <Snackbar
                sx={{
                    marginTop: '0px',
                    '& .MuiSnackbarContent-root': {
                        backgroundColor: 'white',
                        borderTop: '10px solid green',
                        top: "10px"
                    }
                }}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={3000}
                open={showThanks}
                onClose={() => setShowThanks(false)}
                message={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <CheckCircleIcon style={{ marginRight: '8px', color: 'green' }} />
                        <span style={{ color: "#000", fontFamily: 'Red Hat Display', fontWeight: 700, fontSize: "15px" }}>Thank you for contacting Motiv AI. We will get back to you shortly.</span>
                    </div>
                }
            />
        </Grid>
    )
}

export default Contact;
