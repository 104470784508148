import React from "react";
import aboutus from "../../data/aboutus.json"

import { Link } from "react-router-dom";
const PrivacyParagraph = () => {
  const handleScrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <div className="container">
      {aboutus.map((item, index) => (
        <div className="paragraphComp" key={index}>
          {item.companyPreferenceField.contentType === "HEADING" ? (
            <div>
              <h2 className="termsQuestion" style={{ margin: 0, color: "#000", fontFamily: "Segoe UI", fontSize: "10.5px", fontStyle: "normal", fontWeight: 600, marginBottom: "5px",textAlign:`${item.companyPreferenceField.alignmentType}` }}>
                {item.companyPreferenceField.questions[0]}
              </h2>
              {item.companyPreferenceField.answers.map((answer, idx) => (
                answer.trim() !== "" && (
                  <p key={idx} className="termsAnswer" style={{ margin: 0, color: "#000", fontFamily: "Segoe UI", fontSize: "10.5px", fontStyle: "normal", fontWeight: 400, marginBottom: "10px", textAlign:`${item.companyPreferenceField.alignmentType}`}}>
                    {answer}
                  </p>
                )
              ))}
            </div>
          ) : item.companyPreferenceField.contentType === "SUB_HEADING" ? (
            <div>
              <h2 style={{ margin: 0, color: "#000", fontFamily: "Segoe UI", fontSize: "11px", fontStyle: "normal", fontWeight: 600, marginBottom: "5px", marginLeft: "10px"}}>
                {" "}  {item.companyPreferenceField.questions[0]}
              </h2>
              {item.companyPreferenceField.answers.map((answer, idx) => (
                answer.trim() !== "" ? (
                  <p key={idx} style={{ margin: 0, color: "#000", fontFamily: "Segoe UI", fontSize: "11px", fontStyle: "normal", fontWeight: 400, marginBottom: "10px", marginLeft: "10px",textAlign:`${item.companyPreferenceField.alignmentType}`  }}>
                    {answer}
                  </p>
                ) : null
              ))}
            </div>
          ) : null}
        </div>
      ))}
    </div>
  );

  
};

export default PrivacyParagraph;
