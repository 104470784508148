import React from 'react'
import "./headerTitleComp.css"

const TitleComp = ({title , subTitle}) => {
  return (
    <div>
    <div className="heading">
        <div className="topLine">
        </div>
        <div className="title">
          <p>{title}</p>
        </div>
      </div>
    </div>
  )
}

export default TitleComp