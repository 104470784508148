import React from "react";
import SolutionsTitles from "../components/title";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import bgImg from '../../../assets/images/phoneBg-Image.png'
import bgImg1 from '../../../assets/images/iPhoneVFMmobile.png'

import mobilityIcon from "../../../assets/images/mobilityIcon.svg" 
import connectedViechleIcon from "../../../assets/images/connectedViechleIcon.svg" 
import telematicsIcon from "../../../assets/images/telematicsIcon.svg" 

import card1 from "../../../assets/images/card-fleet.png";
import card2 from "../../../assets/images/card-vfmi.png";
import card3 from "../../../assets/images/card-mtp.png";

import "./solutionsPages.css";
import { Link } from "react-router-dom";
import Sstories from "../components/successStories";
import Contact from "../../contact/contact";
import ccImg1 from "../../../assets/images/cc1.png";
import ccImg2 from "../../../assets/images/cc2.png";
import "../../../pages/solutions/pages/motorInsurance.css"

const Solutions6 = () => {
    const iconContent = [
        {
            icon: mobilityIcon,
            title: "Mobility",
            subtitle:
                "Moving people and  things from point A to point B.",
        },
        {
            icon: connectedViechleIcon,
            title: "Connected vehicles",
            subtitle:
                "Internet-enabled, mobile  equipment.",
        },
        {
            icon: telematicsIcon,
            title: "Telematics",
            subtitle: "Hardware and  software to  connect vehicles.",
        },
    ];

    const contentData = [

        {
            src: card1,
            text: "Value for Fleet Management",
            // buttonText: "WHITE PAPER",
            // symbol: arrow,
            linkTo: "/value_for_fleet_management",
        },
        {
            src: card2,
            text: "Value for Motor Insurers",
            // buttonText: "CASE STUDY",
            // symbol: arrow,
            linkTo: "/value_for_motor_insurers",
        },
        {
            src: card3,
            text: "Mobile Telematics Provider",
            // buttonText: "CASE STUDY",
            // symbol: arrow,
            linkTo: "/mobile_telematics_providers_are_commonly_seen_as_disruptive_innovators", // Add the link you want to redirect to
        },
    ];

    return (
        <Grid
            id="fleet"
            marginTop={{ xs: "100px", sm: "40px", md: "60px" }}
            // padding={"0px 25px 0px 30px"}
        >
            <Box padding={"30px 25px 0px 25px"}> <SolutionsTitles /></Box>
            <Grid container padding={"0px 25px 0px 35px"}>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Box
                        component="img"
                        src={ccImg1}
                        alt="InsuranceTopImg"
                        sx={{ width: { xs: "100%", sm: "98%" } }}
                    ></Box>
                </Grid>
                <Grid
                    item
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    sx={{ display: { xs: "none", sm: "flex" }, justifyContent: "right", }}
                >
                    <Box
                        component="img"
                        src={ccImg2}
                        alt="InsuranceTopRightImg"
                        sx={{ width: { xs: "10%", sm: "98%" } }}
                    ></Box>
                </Grid>
            </Grid>
            <Grid className="container-fluid" item xs={12} mt={3} mb={2} sx={{ padding: "10px 40px" }}>
                <Grid container spacing={3}>
                    {iconContent.map((index, key) => (
                        <Grid item xs={12} sm={4} key={key} sx={{width: {md:"343px", sm:"0px", xs:"0px"}}}>
                            <img src={index.icon} height={"42px"} />
                            <Typography className="icontitle"><p>{index.title}</p></Typography>
                            <Box ><Typography className="iconsubtitle"><p>{index.subtitle}</p></Typography></Box>                            
                        </Grid>
                    ))}
                </Grid>
            </Grid>
            <Grid className="subBg" mt={0}>
                <div className="sub4">
                    <Grid container >
                        <Grid item xs={12} sm={6}>
                            <Grid container>
                                <Grid item xs={12} sm={6} display={{ sm: "block", xs: "flex" }} >
                                    <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                    //   display={{ sm: "block", xs: "flex" }}
                                    //   justifyContent={{ sm: "flexstart", xs: "center" }}
                                    >
                                        <div className="leftContent">
                                            Connected car opportunities and challenges
                                        </div>

                                    </Grid>
                                    <Grid xs={6} sm={3} sx={{ textAlign: "end" }}>
                                        <a href="#contact">
                                            <Button variant="contained" className="more" style={{whiteSpace:"nowrap"}}>
                                                Enquire Now
                                            </Button>
                                        </a>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Grid container>
                                <Grid item xs={12} sm={12} className="box">
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <div className="head">From</div>
                                        <div className="content">
                                            <li>
                                                Managed as feature/ functionality item
                                            </li>
                                            <li>
                                                Owned by product development/engineering
                                            </li>
                                            <li>
                                                VM branded
                                            </li>
                                            <li>
                                                Unclear value proposition
                                            </li>
                                            <li>
                                                Optional item bundled in a package
                                            </li>
                                        </div>
                                    </div>
                                </Grid>
                                <div className="line3"></div>
                                <Grid item xs={12} sm={12} className="box">
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <div className="head">
                                            To
                                        </div>
                                        <div className="content">
                                            <li>
                                                Key element of customer engagement
                                            </li>
                                            <li>
                                                Application across ownership lifecycle
                                            </li>
                                            <li>
                                                Services-driven value proposition
                                            </li>
                                            <li>
                                                Multi-vendor,multi-brand solutions
                                            </li>
                                            <li>
                                                Standard item
                                            </li>
                                            <li>
                                                Tight smartphone integration
                                            </li>
                                        </div>
                                    </div>
                                </Grid>
                                <div className="line3"></div>
                                <Grid item xs={12} sm={12} className="box">
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <div className="head">Challenges </div>
                                        <div className="content">
                                            <li>
                                                Defining value and willingness to pay for dealers and customers
                                            </li>
                                            <li>
                                                KISS: Keep it simple for users
                                            </li>
                                            <li>
                                                Organizing for success
                                            </li>
                                            <li>
                                                Operating a services business
                                            </li>
                                            <li>
                                                Managing partnerships vs. vendors
                                            </li>
                                            <li>
                                                Owning and using data
                                            </li>
                                        </div>
                                    </div>
                                </Grid>
                                <div className="line3"></div>
                                <Grid item xs={12} sm={12} className="box" sx={{paddingBottom:{xs:"15px",sm:"15px"}}}>
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <div className="head">Opportunities</div>
                                        <div className="content">
                                            <li>
                                                Differentiate through the connected vehicle experience
                                            </li>
                                            <li>
                                                Build direct relationships with customers
                                            </li>
                                            <li>
                                                Use data to improve quality/ reduce warranty expense
                                            </li>
                                            <li>
                                                Increase share of post warranty, customer pay parts and services spend
                                            </li>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Box className="bgImage" sx={{ display :{ md:"block", sm:"none", xs:"none"}}}>
                            <img src={bgImg} />
                        </Box>
                    </Grid>
                </div>
            </Grid>
            <Grid container sx={{ display: {md:"none", sm:'block', xs:"block", textAlign:"center", justifyContent:"center"}}}>
                <Box sx={{padding:"30px 30px 0px 30px"}}>
                    <img src={bgImg1} alt='bgImg1' style={{ width:"250px", height:"500px"}}/>
                </Box>
            </Grid>
            <Grid xs={12} mt={3} className="container-fluid" sx={{ padding: "20px 40px 0px 40px" }}>
                <Typography sx={{ fontFamily: 'Red Hat Display', fontSize: "20px", fontWeight: 700, textAlign: "left", lineHeight: "24px" }}>Detection of entry into risk triangle, and action for a rapid exit: “undo unsafe act”</Typography>
            </Grid>
            <Grid className="container-fluid solution3-paragraph" sx={{ padding: "20px 40px 0px 40px" }} item xs={12} >
                <div style={{ marginTop: "10px" }} p={"0px 40px 0px 40px"}><span style={{ fontWeight: "600" }}>STEP 1 : Define</span><br/>
                    The first step is to define the fields of play – the focus areas, or points of concern, relative to your overall mobility and telematics program(s)
                    Strategy alignment - Ensuring the alignment of telematics and mobility with overall corporate strategy across different BUs.
                    Risk management - Creating an integrated and dynamic control environment for the connected vehicle strategy that balances value, cost and risk.
                    Customer definition - Defining the key stakeholder and customer groups and determining key solution requirements and pricing.
                    Integration and monitoring - Effectively integrating and monitoring mobility and telematics program efforts and results across BUs.</div>
                <div style={{ marginTop: "10px" }} p={"0px 40px 0px 40px"}><span style={{ fontWeight: "600" }}>STEP 2 : Design & Develop</span><br/>
                    After identifying one or more focus areas, the next step is to design and  develop an integrated and holistic connected vehicle strategy.
                </div>
                <div style={{ marginTop: "10px" }} p={"0px 40px 0px 40px"}><span style={{ fontWeight: "600" }}>STEP 3 : Implement/ Monitor</span><br/>
                    CVS challenges and issues - Technical, Commercial and Operational.
                </div>
            </Grid>
            <Box id="contact" sx={{marginLeft:{md:"40px", sm:"0px", xs:"10px"}}}><Contact /></Box>
            <Box sx={{ padding:"0px 20px"}}><Sstories contentData={contentData} /></Box>
        </Grid>
    );
};

export default Solutions6;
