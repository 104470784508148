import React from "react";
import Banner from "../components/banner/banner";
import SoftwareHero from "./Compoenents/softwareHero/softwareHero";
import HowItWork from "./Compoenents/howItWork/howItWork";
import Whatweprovide from "./Compoenents/whatWeProvide/whatWeProvide";
import ThreeItemGrid from "./Compoenents/driverCoach/driverCoach";
import SafeDrivingScore from "./Compoenents/safeDrivingScore/safeDrivingScore";
import RiskAlert from "./Compoenents/riskAlerts/riskAlerts";
import RiskAlertNext from "./Compoenents/riskAlertNext/riskAlertNext";
import SoftwareL from "./Compoenents/softwareL";
import Software8 from "./Compoenents/Softwarelast";
import "../software/Compoenents/whatWeProvide/whatWeProvide.css";
import HomeSection6 from "../home/HomeSection6";
import HomeSection7 from "../home/HomeSection6";
import Contact from "../contact/contact";
import '../../pages/software/Software.css'

const SoftwareMaster = () => {
  return (
    <>
    <div className="background-video-container" id="section1">
      <SoftwareHero />
      <Banner title="" />
      <HowItWork />
      <div ><Whatweprovide/></div>
      <div style={{marginTop:"50px"}}> <ThreeItemGrid /></div>
      <div ><RiskAlert/></div>
      <SafeDrivingScore />
      <RiskAlertNext />
      <SoftwareL/>
      <div class='whatweprovidebg'><Software8/></div>
      <div style={{marginTop:"50px"}}><Contact/></div>
      <HomeSection7/>
    </div>
    </>
  );
};

export default SoftwareMaster;
