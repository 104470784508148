import React from "react";
import Banner from "../components/banner/banner";
import bannerimage from "../../assets/images/faq-banner.jpg";
import TitleComp from "../components/titleComponent.jsx/headerTitleComp";
import { Typography, createTheme } from "@mui/material";
import { useState } from "react";
import faqData from '../../data/faq.json'
 
const customTheme = createTheme({
  typography: {
    semibold: {
      fontWeight: 600,
      fontFamily: "Red Hat Display",
      fontSize: "12px",
    },
    regular: {
      fontWeight: 400,
      fontFamily: "Red Hat Display",
      fontSize: "12px",
      marginTop: "20px",
      marginBottom: "25px",
    },
  },
});
 

const pageSize = 10;
 
const Faq = () => {
  const [currentPage, setCurrentPage] = useState(1);
 
  const totalPages = Math.ceil(faqData.length / pageSize);
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const currentFaqData = faqData.slice(startIndex, endIndex);
 
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    window.scrollTo(0, 0);

  };
 
  return (
    <>
      <div style={{ marginTop: "64px", marginBottom: "80px" }}>
        <div style={{ width: "100%" }}>
          <img
            src={bannerimage}
            alt="banner"
            className="img-fluid"
            width="100%"
            style={{ height: "400px", objectFit: "cover" }}
          />
        </div>
        <Banner title="" />
        <TitleComp title="Frequently Asked Questions" />
        <div style={{ marginLeft: "30px", marginRight: "30px", marginTop:"20px" }}>
          {currentFaqData.map((item, index) => (
            <div
              key={index}
              style={{
                borderBottom: "1px solid #D9D9D9",
                marginBottom: "30px",
                display: "flex",
              }}
            >
              <div style={{ marginRight: "5px" }}>
                <Typography sx={customTheme.typography.semibold}>
                  {startIndex + index + 1}.
                </Typography>
              </div>
              <div>
                <Typography sx={customTheme.typography.semibold}>
                {item.companyPreferenceField.questions[0].replace(/^\d+\.\s*/, '')}
                </Typography>
                <Typography sx={customTheme.typography.regular}>
                {item.companyPreferenceField.answers[0]}
                </Typography>
              </div>
            </div>
          ))}
        </div>
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          {currentPage > 1 ? (
            <span
              style={{
                marginRight: "5px",
                cursor: "pointer",
                fontWeight:"bold"
              }}
              onClick={() => handlePageChange(currentPage - 1)}
            >
              PREV
            </span>
          ) : (
            <span
              style={{
                marginRight: "5px",
                cursor: "pointer",
                fontWeight: "bold",
                color:"#777",
              }}
            >
              PREV
            </span>
          )}
          {Array.from({ length: totalPages }, (_, index) => (
            <span
              key={index}
              style={{
                marginRight: "5px",
                cursor: "pointer",
                padding: "8px",
                borderRadius: "50%",
                background: currentPage === index + 1 ? "#FCAD0D" : "white",
                fontWeight: currentPage === index + 1 ? "bold" : "normal",
                display: "inline-block",
                border: "1px solid #ccc",
                width:'40px',
              }}
              onClick={() => handlePageChange(index + 1)}
            >
              {index + 1}
            </span>
          ))}
          {currentPage < totalPages ? (
            <span
              style={{
                marginLeft: "5px",
                cursor: "pointer",
                fontWeight:"bold"
              }}
              onClick={() => handlePageChange(currentPage + 1)}
            >
              NEXT
            </span>
          ) : (
            <span
              style={{
                marginRight: "5px",
                cursor: "pointer",
                fontWeight: "bold",
                color:"#777",
              }}
            >
              NEXT
            </span>
          )}
        </div>
      </div>
    </>
  );
};

export default Faq;