import React from "react";
import Contact from "./contact";
import Banner from "../components/banner/banner";
import '../solutions/pages/solutionsPages.css'
import '../../pages/software/Compoenents/howItWork/howItWork.css'
import { Box, Container, Grid, Typography } from "@mui/material";

import icon1 from '../../assets/images/contactIcon.svg';
import icon2 from '../../assets/images/mailIcon.svg';
import icon3 from '../../assets/images/locationIcon.svg';

export default function ContactMain() {

    const iconContent = [
        {
            icon: icon1,
            title: "+91 98183 33440 ",
        },
        {
            icon: icon2,
            title: "selva.ramanujam@motivai.tech",
        },
        {
            icon: icon3,
            title: "122, Appusamy Road, Red Fields, Race Course, Coimbatore 641 045, TN, INDIA",
        }
    ];


    return (
        <div id="contactMain" style={{ marginTop: "50px", marginBottom:"20px" }}>
            <Grid container xs={12} sx={{ padding:{md:"0px 0px 0px 0px", sm:"0px 0px 0px 0px", xs:"20px 0px 0px 0px",}  }}>
                <Grid item xs={12} pt={10} pl={5} pb={0} display={"flex"} justifyContent={"start"}>
                    <Typography sx={{ fontFamily: 'Red Hat Display', fontSize: "26px", fontWeight: 700, textAlign: "left" }}>Get in touch</Typography>
                </Grid>
                <Grid container pl={5} pt={3} pr={5} gap={2.3}>
                    <Grid item md={2.6} sm={12} xs={12}>
                        <Box sx={{display:"flex", alignItems:"center", gap:0.5, cursor:"pointer"}} onClick={(e) => {window.location.href ='tel:+91 98946 00136';}}>
                            <img src={icon1} alt="contactIcon" width={"40px"} height={"40px"} />
                            <Typography sx={{ fontFamily:"Red Hat Display", fontSize:"16px", fontWeight:400, lineHeight:"21px", cursor:"pointer"}}>+91 98946 00136</Typography>
                        </Box>
                    </Grid>
                    <Grid item md={3.4} sm={12} xs={12}>
                        <Box sx={{display:"flex", alignItems:"center", gap:0.5, cursor:"pointer"}} onClick={(e) => {window.location.href ='mailto:selva.ramanujam@motivai.tech';}}>
                            <img src={icon2} alt="contactIcon" width={"40px"} height={"40px"} />
                            <Typography sx={{ fontFamily:"Red Hat Display", fontSize:"16px", fontWeight:400, lineHeight:"21px", cursor:"pointer"}}>selva.ramanujam@motivai.tech</Typography>
                        </Box>
                    </Grid>
                    <Grid item md={5.5} sm={12} xs={12} sx={{marginTop:{sm:"0px", xs:"-30px"}}}>
                        <Box sx={{display:"flex", alignItems:"center", gap:0.5, cursor:"pointer"}} 
                        // onClick={(e) => { window.open('https://goo.gl/maps/wdee5e2AwM5ArwTy6', '_blank'); }}
                        >
                            <img src={icon3} alt="contactIcon" width={"40px"} height={"40px"} />
                            <Typography sx={{ marginTop:{ sm:"0px", xs:"40px"}, fontFamily:"Red Hat Display", fontSize:"16px", fontWeight:400, lineHeight:"21px", cursor:"pointer"}}>122, 3<sup>rd</sup> Floor, Appusamy Road, Red Fields, Race Course, Coimbatore 641 045, TN, INDIA.</Typography>
                        </Box>    
                    </Grid>
                </Grid>
            </Grid>
            <Box id="contact" sx={{ padding:{md:"0px 40px",sm:"0px 20px", xs:"0px 20px"}}}><Contact /></Box>
        </div>
    )
}