import React, { useState, useEffect } from "react";
import { Box, Container, Grid, Paper, Typography } from "@mui/material";
import driverCoachImg_1 from "../../../../assets/images/softwaresafety.svg";
import driverCoachImg_1_1 from "../../../../assets/images/android_ios_energy.png";
import driverCoachImg_2 from "../../../../assets/images/whatweprovide_3.png";

import "./driverCoach.css";

const data = [
  {
    imageSrc1: driverCoachImg_1,
    text: "Next generation of driving coaching and scoring to aid drivers to achieve the safest driving style possible in an urban / rural / highway environment.",
    text_2: "Software acts as a virtual co-pilot in the cabin while driving and provides anticipation guidance when necessary, helping to avoid emergency situations that may lead to an accident.",
    text_3: "The real-world information gathered can then provide drivers with a safety score that reflects their driving style and acts as a catalyst for a range of solutions that motivate safer driving practices.",
    imageSrc2: driverCoachImg_2,
  },
];

const ThreeItemGrid = () => {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 900);

  useEffect(() => {
    // Update the isMobileView state when the window size changes
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 900);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Define the image source based on the isMobileView state
  const imageSrc1 = isMobileView ? driverCoachImg_1_1 : driverCoachImg_1;

  return (
    <div style={{ background: "#F9F9F9", padding: "3rem 0 0rem 0" }} id="drivercoach">
      <div style={{padding:"30px"}}>
        <Grid container spacing={2} style={{ background: "#F9F9F9" }}>
          {data.map((item, index) => (
            <Grid container spacing={2} key={index} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              {/* First Item: Image 1 */}
              <Grid item xs={12} md={4}>
                <Box elevation={3} style={{ textAlign: "center" }} className="firstimage">
                  <img
                    src={imageSrc1}
                    alt="Image 1"
                    style={{
                      height: "336px",
                      width: "100%"
                    }}
                  />
                </Box>
              </Grid>

              {/* Second Item: Text */}
              <Grid item xs={12} md={4}>
                <Box elevation={3} sx={{ padding: "1rem", marginLeft:{md:"0px", sm:"10px", xs:"10px"} }}>
                  <Typography variant="body1" className="driverCoachDesc" my={2} textAlign={'left'}  style={{paddingTop:"20px", fontFamily:'Red Hat Display'}}>{item.text}</Typography>
                  <Typography variant="body1" className="driverCoachDesc" my={2} textAlign={'left'} style={{paddingTop:"20px", fontFamily:'Red Hat Display'}}>{item.text_2}</Typography>
                  <Typography variant="body1" className="driverCoachDesc" my={2} textAlign={'left'} style={{paddingTop:"20px", fontFamily:'Red Hat Display'}}>{item.text_3}</Typography>
                </Box>
              </Grid>

              {/* Third Item: Image 2 */}
              {/* <Grid item xs={12} md={4}>
                <Box elevation={3} style={{ textAlign: "center" }} className="secondimage">
                  <img
                    src={item.imageSrc2}
                    alt="Image 2"
                    style={{
                      maxWidth: "70%", // Make the image responsive
                      height: "auto", // Maintain aspect ratio
                      paddingTop: "50px"
                    }}
                  />
                </Box>
              </Grid> */}
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
};

export default ThreeItemGrid;
