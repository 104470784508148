import React from "react";
import whatWeProvideImg from "../../../../assets/images/FlyingIphoneleftview.png";
import { Box,Grid, Typography } from "@mui/material";
import "./whatWeProvide.css";

const data = [
  {
    imageSrc: whatWeProvideImg,
    title: "Say Hello to our AI",
    description:
      "Disruptive software component that computes dynamically, onboard, in real time, and at each moment, the risk that the driver is currently taking. Alerting driver when risk is rising too much, letting him / her time to slightly slow down and avoid emergency situation that can reduce accident rate at least by 25%.",
  },
];

const Whatweprovide = () => {
  return (
    <div class='container'>
      {" "}
      <div style={{padding:"30px 90px 0px 0px"}}>
        {/* <Box mt={3}>
          <Typography
            variant="h5"
            fontFamily={"Segoe UI"}
            textAlign={"center"}
            fontSize={"22px"}
            fontWeight={"600"}
            color={"#000"}
          >
            WHAT WE PROVIDE
          </Typography>
        </Box> */}
        {data.map((item, index) => (
          <Grid  container spacing={2} key={index} style={{margin:"0rem 0"}}>
            {/* Left: Image */}
            <Grid item xs={12} md={6}>
              <Box
                elevation={3}
                style={{ display: "flex", alignItems: "center" }}
              >
                <div style={{ flex: 1 }}>
                  <img
                    src={item.imageSrc}
                    alt=""
                    style={{
                      maxWidth: "70%", // Make the image responsive
                      height: "auto", // Maintain aspect ratio
                    }}
                  />
                </div>
              </Box>
            </Grid>

            {/* Right: Title and Paragraph */}
            <Grid item xs={12} md={6} display={"flex"} alignItems="center">
              <div style={{ flex: 1, padding: "1rem" }}>
                <Typography sx={{ textAlign:{ md:"", xs:"left", sm:"left"}}}
                  variant="h5"
                  fontFamily={"Red Hat Display"}
                  fontWeight={600}
                  fontSize={"20px"}
                  gutterBottom
                  className="whatWeProvideTitle"
          
                >
                  {item.title}
                </Typography>
                <Typography variant="body1" className="whatWeProvideTitleDesc" style={{fontSize:"16px", fontFamily:'Red Hat Display'}}>
                  {item.description}
                </Typography>
              </div>
            </Grid>
          </Grid>
        ))}
      </div>
    </div>
  );
};

export default Whatweprovide;
