 export const SC_USERNAME = 'username';
 export const SC_USER_EMAIL = 'userEmail';
 export const SC_NAME = 'name';
 export const SC_USER_FIRST_NAME = 'userFirstName';
 export const SC_USER_LAST_NAME = 'userLastName';
 export const SC_EMAIL = 'email';
 export const SC_USER_ID = 'userId';
 export const SC_FCM_NOTIFICATION_TOKEN = 'fcmToken';
 export const SC_FCM_NOTIFICATION_COUNT = 'fcmCount';
 export const SC_LOGIN_USER_FIRST_NAME = 'loginUserFirstName';
 export const SC_LOGIN_USER_ID = 'loginUserId';
 export const SC_LOGIN_GROUP_ID = 'loginGroupId';

 export const SC_COMPANY_ID = 'companyId';
 export const SC_DIVISION_ID = 'divisionId';
 export const SC_MODULE_ID = 'moduleId';
 export const SC_GROUP_ID = 'groupId';
 export const SC_COMPANY_NAME = 'companyName';
 export const SC_DIVISION_NAME = "divisionName";
 export const SC_REGISTRATION_DATE = "userRegistrationDate";
 export const SC_LOGIN_DIVISION_ID = 'loginDivisionId';
 export const SC_LOGIN_MODULE_ID = 'loginModuleId';
 export const SC_USER_SESSION_ID = 'userSessionId';
 export const SC_EXPIRATION_TIME = 'expirationTime';
 export const SC_USER_LOGIN_TYPE = 'userLoginType';
 export const SC_USER_LAST_RIDE_ID = 'userLastRideId';
 export const SC_LANGUAGES = 'languages';
 export const SC_DASHBOARD_TYPE = 'dashboardType';
 export const SC_PASSWORD = 'password';
 export const SC_LOCK_STATUS = 'lockStatus';
 export const SC_TOTAL_RIDE_COUNT = 'totalRideCount';
 export const SC_PROFILE_OPEN = 'profileStatus';
 export const SC_SECRET_KEY = 'secretKey';