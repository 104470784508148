import React from 'react'
import SwiperComponent from '../components/Swiper/SwiperComponent'
const HomeSection2 = () => {
    return (
        <>
            <div id='section2'>
                <div class='container-fluid' style={{margin:" 5% 0%"}}>
                    <SwiperComponent />
                </div>
            </div>

        </>
    )
}

export default HomeSection2