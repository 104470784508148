// SoftwareHero.js
import React from "react";
import { Box, Grid } from "@mui/material";
import heroImage from "../../../../assets/images/heroImage.png";
import heroSubImage_2 from "../../../../assets/images/heroSubImage_2.png";
import heroSubImage_3 from "../../../../assets/images/heroSubImage_3.png";
import "./softwareHero.css";

const SoftwareHero = () => {
  return (
    <Box className="softwareHero" style={{ marginTop: "64px" }}>
      <Grid container spacing={3} className="gridContainer">
        <Grid item xs={12} sm={8}> 
          <Grid container className="softmain" >
            <Grid item xs={4} >
              <img src={heroImage} className="heroimg" alt="" />
            </Grid>
            <Grid item xs={8} className="sub2" >
              <Grid container className="main">
                <Grid item xs={4} class="herotext">
                  <p style={{ color: "white", fontFamily:"Red Hat Display" }}>Drive Safe,</p>
                  <p style={{ color: "white", fontFamily:"Red Hat Display" }}>Arrive Safe.</p>
                </Grid>
                <Grid item xs={4} class="herosubtext">
                  <p style={{ color: "white", fontFamily:"Red Hat Display" }}>
                    Mobile app based hybrid physics-informed
                  </p>
                  <p style={{ color: "white", fontFamily:"Red Hat Display" }}>
                    Artificial Intelligence telematics software
                  </p>
                </Grid>
                <Grid item xs={4} class="herobottomimage">
                  <Grid container className="last">
                    <Grid item xs={6} sm={5} md={4}  className="heroimg2" style={{padding:'5px'}}>
                      <a
                        href="https://play.google.com/store/apps/details?id=com.montbleu.roadsafety"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={heroSubImage_2} alt="" />
                      </a>
                    </Grid>

                    <Grid item xs={6} sm={5} md={4} className="heroimg3" style={{padding:'5px',  }}>
                      <a
                        href="https://apps.apple.com/in/app/motiv-ai/id1640693505"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={heroSubImage_3} alt="" />
                      </a>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={4}></Grid>
      </Grid>
    </Box>
  );
};

export default SoftwareHero;
