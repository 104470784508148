import React from "react";
import SolutionsTitles from "../components/title";
import { Box, Button, Container, Grid } from "@mui/material";
import img1 from "../../../assets/images/fleet1.png";
import img2 from "../../../assets/images/fleet2.png";

import bgImg from "../../../assets/images/sol2-bg.png";
import bgImg1 from "../../../assets/images/iPhoneVFMmobile.png";

import icon1 from '../../../assets/images/rncIcon.svg'
import icon2 from '../../../assets/images/ddcIcon.svg'
import icon3 from '../../../assets/images/dccIcon.svg'

import card1 from "../../../assets/images/card-mtp.png";
import card2 from "../../../assets/images/card-iamt.png";
import card3 from "../../../assets/images/card-driveWell.png";

import "./solutionsPages.css";
import { Link } from "react-router-dom";
import Sstories from "../components/successStories";
import Contact from "../../contact/contact";
import InsuranceTopImg from "../../../assets/images/InsurersImageCasetwo.svg";
import InsuranceTopRightImg from "../../../assets/images/SecondInsurersImageCaseTwo.svg";
import "../../../pages/solutions/pages/motorInsurance.css"

const Solutions2 = () => {
  const iconContent = [
    {
      icon: icon1,
      title: "Road Network Context",
      subtitle:
        "Road type, road familiarity, rural / urban, regions, speed zones, etc.",
    },
    {
      icon: icon2,
      title: "Driving Dynamics Context",
      subtitle:
        "Speed, acceleration, braking, cornering, risk against road attributes, reckless events.",
    },
    {
      icon: icon3,
      title: "Driving Condition Context",
      subtitle: "Day / night, rush hours, weekday/weekend, trip duration.",
    },
  ];

  const contentData = [
    {
      src: card1,
      text: "Mobile Telematics Provider",
      // buttonText: "CASE STUDY",
      // symbol: arrow,
      linkTo:
        "/mobile_telematics_providers_are_commonly_seen_as_disruptive_innovators", // Add the link you want to redirect to
    },
    {
      src: card2,
      text: "Insurance and Mobile Telematics",
      // buttonText: "WHITE PAPER",
      // symbol: arrow,
      linkTo:
        "/insurance_companies_are_setting_for_a_profit_gain_from_the_mobile_telematics_systems", // Add the link you want to redirect to
    },
    {
      src: card3,
      text: "What Does It Mean To Drive Well",
      // buttonText: "CASE STUDY",
      // symbol: arrow,
      linkTo: "/what_does_it_mean_to_drive_well_outside_of_any_emergency_situation", // Add the link you want to redirect to
    },
  ];

  return (
    <Grid
      id="fleet"
      marginTop={{ xs: "100px", sm: "40px", md: "60px" }}
      // padding={"0px 25px 0px 30px"}
    >
      <Box padding={"0px 20px 0px 20px"}><SolutionsTitles /></Box>
      <Grid container padding={"0px 30px 0px 30px"}>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <Box
            component="img"
            src={InsuranceTopImg}
            alt="InsuranceTopImg"
            sx={{ width: { xs: "100%", sm: "98%" } }}
          ></Box>
        </Grid>
        <Grid
          item
          sm={6}
          md={6}
          lg={6}
          xl={6}
          sx={{ display: { xs: "none", sm: "flex" }, justifyContent: "right", }}
        >
          <Box
            component="img"
            src={InsuranceTopRightImg}
            alt="InsuranceTopRightImg"
            sx={{ width: { xs: "10%", sm: "98%" } }}
          ></Box>
        </Grid>
      </Grid>
      <Grid className="container-fluid" item xs={12} mt={8} mb={2} sx={{ display: { xs: "none", sm: "block" }, padding: "0px 36px" }}>
        <Grid container spacing={3} >
          {iconContent.map((index, key) => (
            <Grid item xs={12} sm={4} key={key}>
              <img src={index.icon} height={"42px"} />
              <div >
                <p className="motorIcontitle">{index.title}</p>
              </div>
              <div >
                <p className="motorSubtitleNew">{index.subtitle}</p>
              </div>
            </Grid>
          ))}
        </Grid>

      </Grid>


      <Grid className="container-fluid" item xs={12} mt={3} mb={2} sx={{ display: { xs: "block", sm: "none" }, padding: "10px 36px" }}>
        <Grid  >
          {iconContent.map((index, key) => (
            <Grid item xs={12} sm={4} key={key}>
              <img src={index.icon} height={"42px"} />
              <div >
                <p className="motorIcontitle">{index.title}</p>
              </div>
              <div style={{ paddingBottom: "10px" }} >
                <p className="motorSubtitleNew">{index.subtitle}</p>
              </div>
            </Grid>
          ))}
        </Grid>
      </Grid>


      <Grid className="subBg" mt={0}>
        <div className="sub4">
          <Grid container >
            <Grid item xs={12} sm={6}>
              <Grid container>
                <Grid item xs={12} sm={6} display={{ sm: "block", xs: "flex" }} >
                  <Grid
                    item
                    xs={12}
                    sm={6}
                  //   display={{ sm: "block", xs: "flex" }}
                  //   justifyContent={{ sm: "flexstart", xs: "center" }}
                  >
                    <div className="leftContent">
                      A driving solution for motor insurers
                    </div>

                  </Grid>
                  <Grid xs={6} sm={3} sx={{ textAlign: "end" }}>
                    <a href="#contact">
                      <Button variant="contained" className="more" style={{whiteSpace:"nowrap"}}>
                        Enquire Now
                      </Button>
                    </a>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Grid container>
                <Grid item xs={12} sm={12} className="box">
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div className="head">Connected Insurance</div>
                    <div className="content">
                      <li>
                        Solutions that reduce risk, lower claim costs, improve
                        customer service and increase engagement
                      </li>
                    </div>
                  </div>
                </Grid>
                <div className="line3"></div>
                <Grid item xs={12} sm={12} className="box">
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div className="head">
                      Innovative Risk Segmentation and Pricing
                    </div>
                    <div className="content">
                      <li>
                        Empowering insurers to use precise data based and
                        controllable variables in their auto insurance rating
                      </li>
                    </div>
                  </div>
                </Grid>
                <div className="line3"></div>
                <Grid item xs={12} sm={12} className="box">
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div className="head">Reduce claims cost </div>
                    <div className="content">
                      <li>
                        Access key details like driving risk alerts,
                        anticipation, self confidence, vehicle impact location
                        etc.
                      </li>
                    </div>
                  </div>
                </Grid>
                <div className="line3"></div>
                <Grid item xs={12} sm={12} className="box" sx={{paddingBottom:{xs:"15px",sm:"15px"}}}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div className="head">Improve your book of business</div>
                    <div className="content">
                      <li>
                        Improve your book by identifying, engaging, and
                        rewarding safe drivers
                      </li>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Box className='bgImage' sx={{ display :{ md:"block", sm:"none", xs:"none"}}}>
              <img src={bgImg} />
            </Box>
          </Grid>
        </div>
      </Grid>
      <Grid container sx={{ display: {md:"none", sm:'block', xs:"block", textAlign:"center", justifyContent:"center"}}}>
          <Box sx={{padding:"30px 30px 0px 30px"}}>
              <img src={bgImg1} alt='bgImg1' style={{ width:"250px", height:"500px"}}/>
          </Box>
      </Grid>
      <Box id="contact" sx={{marginLeft:{md:"40px", sm:"0px", xs:"10px"}}}><Contact /></Box>
      <Box sx={{ padding:"0px 20px"}}><Sstories contentData={contentData} /></Box>
    </Grid>
  );
};

export default Solutions2;
